import styles from './alertBanner.module.css';
import Icon, { IconType } from '../Icon';

/**
 * Displays a customizable banner style alert message.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {boolean} config.open - Flag indicating whether the alert should be displayed or not.
 * @param {function} config.closeAlert -The function to call to hide the alert.
 * @param {string} [config.type] - Which type of alert should be displayed. Possible options are 'warning' or 'info'. 'info' is default.
 * @param {string} config.title - The header text for the alert.
 * @param {string} config.body - The body text for the alert.
 * @param {boolean} config.allowDismiss - Flag indicating whether the close button should be displayed.
 *
 * @example
 * <AlertBanner open={alertOpen} closeAlert={() => setAlertOpen(false)} type={'info'} title={'Coming right up!'} body={'We`re getting the document you requested.'} />
 */
export default function AlertBanner({
  open,
  closeAlert,
  title,
  body,
  type = 'info',
  allowDismiss,
}: {
  open: boolean;
  closeAlert: any;
  title: string;
  body: string;
  type: string;
  allowDismiss: boolean;
}) {
  let icon = 'sparkle';

  if (type === 'warning') {
    icon = 'error_circle';
  }

  if (!open) {
    return;
  }
  return (
    <div className={[styles.alert, styles[type]].join(' ')}>
      <Icon
        type={icon as IconType}
        color={type === 'warning' ? 'error' : 'text-primary'}
        size={32}
        viewbox={'-2 -2 24 24'}
      />
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{body}</div>
      </div>
      {allowDismiss && (
        <div className={styles.closeIcon} onClick={closeAlert}>
          <Icon
            type={'dismiss'}
            color={type === 'warning' ? 'error' : 'text-primary'}
            size={18}
            viewbox={'-3 -3 24 24'}
          />
        </div>
      )}
    </div>
  );
}
