/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  callCrmWhoAmI,
  getAccessToken,
  WhoAmIResponse,
} from '@/controllers/common/utils';
import {
  getAgent,
  getAgentFlags,
  setAgentFlag,
} from '@/services/opportunity/network/dynamics';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import { AgentFlags } from '@/models/agent';
import { IPublicClientApplication } from '@azure/msal-browser';

const loadCrmData = async (
  incAccessToken: string,
  callback: (value: WhoAmIResponse | undefined) => void
) => {
  try {
    const response = await callCrmWhoAmI(incAccessToken);
    callback(response);
  } catch (error) {
    console.log('Failed to load crm data', error);
  }
};
const loadUserAgentFlags = async (
  incUserId: string,
  incAccessToken: string,
  callback: (value: AgentFlags | undefined) => void
) => {
  const userAgent = await getAgentFlags(incUserId, incAccessToken);
  callback({
    bwFenrisLegalAcknowledgement: userAgent?.bw_fenrislegalacknowledgement,
  });
};

const loadAccessToken = async (
  instance: IPublicClientApplication,
  callback: (value: string) => void
) => {
  try {
    const token = await getAccessToken(instance);
    callback(token);
  } catch (error) {
    console.log('Failed to load access token', error);
  }
};

export const useAgentAndUserInfo = ({
  crmAgentId,
}: {
  crmAgentId?: string;
}) => {
  const { instance } = useMsal();
  const [accessToken, setAccessToken] = useState('');
  const [crmData, setCrmData] = useState<WhoAmIResponse | undefined>();
  const [agentName, setAgentName] = useState<string | undefined>();
  const [userAgentFlags, setUserAgentFlags] = useState<
    AgentFlags | undefined
  >();

  const getAssignedAgent = useCallback(
    async (agentId: string) => {
      try {
        const agent = await getAgent(agentId, accessToken);
        setAgentName(agent?.fullname);
      } catch (error) {
        console.error('Failed to fetch agent data', error);
      }
    },
    [accessToken]
  );

  const confirmUserAgentFlag = useCallback(
    async (flagName: keyof AgentFlags, fetchFn: typeof fetch) => {
      if (crmData) {
        const response: AgentFlags | null = await setAgentFlag(
          crmData.UserId,
          flagName,
          fetchFn
        );

        const flagsPatch = {
          [flagName]: response?.[flagName],
        };

        setUserAgentFlags((flags) => {
          return {
            ...flags,
            ...flagsPatch,
          };
        });
      }
    },
    [crmData]
  );

  useEffect(() => {
    loadAccessToken(instance, setAccessToken);
  }, [instance, accessToken, setAccessToken]);

  useEffect(() => {
    if (!crmData && accessToken) {
      loadCrmData(accessToken, setCrmData);
    } else if (crmData?.UserId && !userAgentFlags) {
      loadUserAgentFlags(crmData.UserId, accessToken, setUserAgentFlags);
    }
  }, [crmData, accessToken, setCrmData, userAgentFlags]);

  useEffect(() => {
    if (crmAgentId && crmData && crmAgentId !== crmData.UserId) {
      getAssignedAgent(crmAgentId);
    }
  }, [crmAgentId, crmData, accessToken, getAssignedAgent]);

  return {
    crmData,
    agentName,
    userAgentFlags,
    confirmUserAgentFlag,
  };
};
