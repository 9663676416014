import Header from './components/header';
import { useParams } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import Submission from './components/SubmissionController';
import { isUUID } from '@/models/string';

export default function Page({ pca }: { pca: IPublicClientApplication }) {
  const { id: submissionId } = useParams();

  if (!submissionId || !isUUID(submissionId)) {
    return (
      <>
        <Header />
        <div
          style={{
            width: '100%',
            minHeight: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Invalid submission ID provided</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <Submission submissionId={submissionId} />
    </>
  );
}
