import type { TableTextInterface } from '@/components/shared/table';
import type { Contact } from '@/models/document/contact';
import type { Customer } from '@/models/document/customer';
import type { Document } from '@/models/document/document';
import type { Opportunity } from '@/models/document/opportunity';
import type { Policy } from '@/models/document/policy';
import { atom } from 'jotai';

export const accountIdAtom = atom<string>('');
export const opportunityIdAtom = atom<string>('');
export const userNameAtom = atom<string>('');
export const customerAtom = atom<Customer>({} as Customer);
export const associatedContactListAtom = atom<Contact[]>([]);
export const documentsListAtom = atom<Document[]>([]);
export const selectedDocumentsListAtom = atom<Document[]>([]);
export const opportunityListAtom = atom<Opportunity[]>([]);
export const policyListAtom = atom<Policy[]>([]);
export const categoryListAtom = atom<string[]>([]);
export const tableTextAtom = atom<TableTextInterface>({
  text: '',
  subText: '',
});
