import { Driver, DrivingIncident } from '@bwinsurance/meta-rater-types';
import { DisplayValueOptional } from '../DisplayValue';
import SubheadingDivider from '../SubheadingDivider';

const IncidentDisplay = ({ incident }: { incident: DrivingIncident }) => {
  if (!incident) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap gap-5">
      <DisplayValueOptional
        label="Incident type"
        value={incident.incidentType}
      />
      <DisplayValueOptional
        label="Violation type"
        value={incident.violationType}
      />
      <DisplayValueOptional
        label="Incident date"
        value={incident.incidentDate}
        isDate
      />
      <DisplayValueOptional label="At fault" value={incident.isAtFault} />
    </div>
  );
};

const DriverInfo = ({ driver }: { driver: Driver | null }) => {
  if (!driver) {
    return null;
  }

  const incidentDisplays = driver?.incidents?.map((incident, index) => (
    <IncidentDisplay incident={incident} key={`${driver?.id}${index}`} />
  ));
  return (
    <>
      <SubheadingDivider headingText="General Info">
        <div className="flex flex-row flex-wrap gap-5">
          <DisplayValueOptional
            label="First name"
            value={driver.name?.firstName}
          />
          <DisplayValueOptional
            label="Middle name"
            value={driver.name?.middleName}
          />
          <DisplayValueOptional
            label="Last name"
            value={driver.name?.lastName}
          />
          <DisplayValueOptional label="Gender" value={driver.gender} />
          <DisplayValueOptional
            label="Birthdate"
            value={driver.dateOfBirth}
            isDate
          />
          <DisplayValueOptional label="Education" value={driver.education} />
          <DisplayValueOptional
            label="License number"
            value={driver.license?.licenseNumber}
          />
          <DisplayValueOptional
            label="License state"
            value={driver.license?.state}
          />
          <DisplayValueOptional
            label="License date"
            value={driver.license?.licensedDate}
            isDate
          />
          <DisplayValueOptional
            label="License status"
            value={driver.license?.status}
          />
          <DisplayValueOptional
            label="License suspended/revoked"
            value={driver.hasLicenseRevokedSuspended}
          />
          <DisplayValueOptional
            label="Marital status"
            value={driver.maritalStatus}
          />
          <DisplayValueOptional label="Relation" value={driver.relation} />
          <DisplayValueOptional label="Occupation" value={driver.occupation} />
          <DisplayValueOptional
            label="Good student"
            value={driver.isGoodStudent}
          />
          {/* <DisplayValueOptional label="WHAT LABEL HERE?" value={driver.isStudent100} /> */}
          <DisplayValueOptional
            label="Has driver training"
            value={driver.hasDriverTraining}
          />
          <DisplayValueOptional
            label="Good driver"
            value={driver.isGoodDriver}
          />
          {/* <DisplayValueOptional label="WHAT LABEL HERE?" value={driver.isMATDriver} /> */}
          <DisplayValueOptional
            label="Has received ticket/violation"
            value={driver.hasReceivedViolation}
          />
          <DisplayValueOptional
            label="Has accident within 5 years"
            value={driver.hasAccidentWithinLastFiveYears}
          />
        </div>
      </SubheadingDivider>

      <SubheadingDivider headingText="Incidents">
        {incidentDisplays?.length && (
          <div className="flex flex-col gap-5">{incidentDisplays}</div>
        )}
      </SubheadingDivider>
    </>
  );
};

export default DriverInfo;
