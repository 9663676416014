import { useEffect, useState } from 'react';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';

const Page = () => {
  const [data, setData] = useState<Array<unknown> | undefined>();
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const res = await authedFetch(
          '/apim/agency/v1/agency/0001/storeNumber'
        );
        const result = await res.json();
        setData(result);
      };
      fetchData().catch((cause: unknown) =>
        console.error(
          'An error occured while attempting to `fetchData` for agency.',
          cause
        )
      );
    }
  }, [data, authedFetch]);
  return (
    <>
      <h1>Agency</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
};

export default Page;
