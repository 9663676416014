import type { FC } from 'react';
import { useEffect } from 'react';
import type { FormField, FormSection } from '@/services/forms/models/formTypes';
import { useFormContext } from 'react-hook-form';
import SubheadingDivider from '../../SubheadingDivider';
import FieldMapper from '../FieldMapper';
import { useVisible } from '@/controllers/common/hooks/utility';
import { SearchResultField } from '@/services/opportunity/utils/formSearch';

interface FormSectionProps {
  section: FormSection;
  searchResults?: SearchResultField[] | null;
}

const FormSection: FC<FormSectionProps> = ({ section, searchResults }) => {
  const { setValue } = useFormContext();
  const isVisible = useVisible(section.visibleIf);

  useEffect(() => {
    if (!isVisible) {
      section.elements.forEach((field) => {
        setValue(field.name, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    }
  }, [isVisible]);

  if (!isVisible) return;

  return (
    <SubheadingDivider
      headingText={section.display}
      headingActions={section.actions}
    >
      {section.elements.map((field: FormField) => {
        field.highlight =
          searchResults?.some(
            (result: SearchResultField) => field.display === result.display
          ) || null;
        return (
          <FieldMapper
            key={field.id || field.name}
            field={field}
            searchResults={searchResults}
          />
        );
      })}
    </SubheadingDivider>
  );
};

export { FormSection };
