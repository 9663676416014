import { useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { Document, DocumentFieldType } from '@/models/document/document';
import { saveNote } from '@/services/document/documentService';
import TextField from '@/components/shared/textField';
import Icon from '@/components/Icon';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import styles from './edit.module.css';

export default function Edit({
  selectedDocumentList,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
  refreshData,
  updateField,
}: {
  selectedDocumentList: Document[];
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: DocumentFieldType,
    value: any
  ) => void;
}) {
  const [notes, setNotes] = useState<string>(selectedDocumentList[0].notes);
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = () => {
    setLoading(true);
    saveNote(selectedDocumentList, notes, authedFetch)
      .then((result: Document[]) => {
        closeDialog();
        showAlertToast('success', `Note added: "${notes}"`);
        deselectAll();
        selectedDocumentList.forEach((document: Document) => {
          updateField(document.id, 'note', notes);
        });
        refreshData();
      })
      .catch(() => {
        showAlertToast('warning', 'Error adding notes');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Edit note</h5>
        <div className={styles.closeIcon} onClick={closeDialog}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <TextField
          label={'Note'}
          theme={'crm'}
          value={notes}
          onChange={(value: string) => setNotes(value)}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSave}
          type={'primary'}
          theme={'crm'}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={closeDialog} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
