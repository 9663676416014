import { FusionForm } from '../../../../models/forms/form';
import FormListSection from './FormListSection';
import Checkbox from '@/components/shared/checkbox';
import { useFormContext } from '../form/FormContext';
import styles from './FormList.module.css';
import { getAccountUpdateFormLink } from './helpers';

interface Props {
  accountId: string;
  customerId: string | undefined;
}

const FormList: React.FC<Props> = ({ accountId, customerId }) => {
  const { toggleFormKey, selectedFormListKeys, forms } = useFormContext();

  return (
    <div className={styles['form-list']}>
      {forms.map((form: FusionForm) => {
        const hasFormPolicies = form.policies?.length
          ? form.policies.length > 0
          : false;
        if (hasFormPolicies) {
          return <FormListSection form={form} accountId={accountId} />;
        } else {
          //individual form
          const formIsSelected = selectedFormListKeys.includes(form.key);
          return (
            <div className={styles.form} key={form.key}>
              <Checkbox
                checked={formIsSelected}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  toggleFormKey(form.key);
                }}
                label={form.name}
                theme={'crm'}
              />
              {customerId && (
                <a
                  className={styles['form-link']}
                  href={getAccountUpdateFormLink(
                    customerId,
                    accountId,
                    form.key
                  )}
                  rel="noreferrer"
                  target="_blank"
                >
                  Complete Form
                </a>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default FormList;
