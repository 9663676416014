import styles from './FenrisLegalAcknowledgementDialog.module.css';
import Dialog from '@/components/shared/dialog';
import FenrisLegalAcknowledgementBody from './FenrisLegalAcknowledgementBody';
import Button from '@/components/shared/button';
import Icon from '@/components/Icon';

const FenrisLegalAcknowledgementHeader = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  return (
    <header className={styles['header']}>
      <h5 className={styles['title']}>Acknowledge Terms</h5>
      <div className={styles['closeIcon']} onClick={handleClose}>
        <Icon type={'dismiss'} color={'black'} size={20} />
      </div>
    </header>
  );
};

const FenrisLegalAcknowledgementFooter = ({
  handleConfirm,
  handleClose,
}: {
  handleConfirm: (ev: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}) => {
  return (
    <footer className={styles['footer']}>
      <Button callback={handleConfirm} type={'primary'} theme={'crm'}>
        Acknowledge
      </Button>
      <Button callback={handleClose} type={'secondary'} theme={'crm'}>
        Cancel
      </Button>
    </footer>
  );
};

const FenrisLegalAcknowledgementDialog = ({
  open,
  setOpen,
  confirm,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  confirm: () => void;
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    void confirm();

    handleClose();
  };

  return (
    <Dialog theme={'crm'} width={728} open={open}>
      <FenrisLegalAcknowledgementHeader handleClose={handleClose} />
      <FenrisLegalAcknowledgementBody />
      <FenrisLegalAcknowledgementFooter
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Dialog>
  );
};

export default FenrisLegalAcknowledgementDialog;
