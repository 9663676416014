import { useEffect, useState, useMemo } from 'react';
import { useFormPageContext } from '../FormPage/FormPageContext';
import { useFormContext } from 'react-hook-form';
import { OptionsConfig } from '@/services/forms/models/formTypes';
import type { EAVAttribute } from '@bwinsurance/common-utils';

export const usePopulateInput = () => {
  const [isWaitingForOptionsLoad, setIsWaitingForOptionsLoad] = useState(false);
  const [targetFieldName, setTargetFieldName] = useState<string>();
  const [updateValue, setUpdateValue] = useState<string>();
  const [watchedOptionList, setWatchedOptionList] = useState<OptionsConfig[]>();
  const { apiOptionMap } = useFormPageContext();
  const { getValues, setValue } = useFormContext();

  const wasOptionListUpdated = useMemo(() => {
    const mapList = targetFieldName ? apiOptionMap[targetFieldName] : '';
    if (!mapList || !watchedOptionList) {
      return false;
    }

    const firstList = JSON.stringify(mapList);
    const secondList = JSON.stringify(watchedOptionList);

    return !(firstList === secondList);
  }, [apiOptionMap, watchedOptionList, targetFieldName]);

  useEffect(() => {
    if (isWaitingForOptionsLoad && wasOptionListUpdated && targetFieldName) {
      setValue(targetFieldName, updateValue, { shouldDirty: true });
      setIsWaitingForOptionsLoad(false);
      setWatchedOptionList(apiOptionMap[targetFieldName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaitingForOptionsLoad,
    wasOptionListUpdated,
    targetFieldName,
    updateValue,
    setValue,
  ]);

  const populateInputValue = ({
    sourceField,
    targetField,
    shouldWatchOptionList,
    overwriteFieldValue,
  }: {
    sourceField: string;
    targetField: EAVAttribute;
    shouldWatchOptionList: boolean;
    overwriteFieldValue?: string;
  }) => {
    if (overwriteFieldValue !== undefined) {
      setValue(targetField, overwriteFieldValue, { shouldDirty: true });
      return;
    }

    const srcValue = getValues(sourceField);

    if (srcValue) {
      setValue(targetField as EAVAttribute, srcValue, { shouldDirty: true });

      if (shouldWatchOptionList) {
        setIsWaitingForOptionsLoad(true);
        setTargetFieldName(targetField);
        setUpdateValue(srcValue);
        setWatchedOptionList(apiOptionMap[targetField]);
      }
    }
  };

  return { populateInputValue };
};
