import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../../DisplayValue';
import Section from '../../Section';
import If from '../../If';
import CoverageDetailsList from '../../CoverageDetailsList/eav';
import SubheadingDivider from '../../SubheadingDivider';
import { PriorPolicy } from '../../PriorPolicyInfo/eav';
import ErrorBoundary from '../../ErrorBoundary';
import { EAV } from '@bwinsurance/common-utils';

const HomeInfo = ({ eav }: { eav?: EAV }): ReactNode => {
  if (!eav?.getChildren({ prefix: 'home.' })?.length) {
    return null;
  }

  const datasource = eav?.getValue('application.metadata.canopyPullId')
    ? 'Canopy Connect'
    : 'Intake Form';

  const streetAddress = eav?.getValue('home.address.lineOne')
    ? `${eav?.getValue('home.address.lineOne')} ${
        eav?.getValue('home.address.lineTwo') ?? ''
      }`
    : undefined;
  return (
    <Section headingText="Property" materialIcon="home">
      <SubheadingDivider headingText="Address">
        <DisplayValueOptional label="Street" value={streetAddress} />
        <DisplayValueOptional
          label="City"
          value={eav?.getValue('home.address.city')}
        />
        <DisplayValueOptional
          label="State"
          value={
            eav?.getValue('home.address.state') ||
            eav?.getValue('home.address.stateCode')
          }
        />
        <DisplayValueOptional
          label="Zip code"
          value={eav?.getValue('home.address.postalCode')}
        />
        <DisplayValueOptional
          label="Years at address"
          value={eav?.getValue('home.address.yearsAtAddress')}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Policy Info">
        <DisplayValue label="Source" value={datasource} />
        <DisplayValueOptional
          label="Effective date"
          value={eav?.getValue('home.policy.effectiveDate')}
          isDate
        />
        <DisplayValueOptional
          label="Estimated premium"
          value={eav?.getValue('home.estimatedPremium')}
          isCurrency
        />
        <DisplayValueOptional
          label="Multi-policy"
          value={eav?.getValue<'boolean'>('isMultiPolicy', 'boolean')}
        />
        <DisplayValueOptional
          label="Policy type"
          value={eav?.getValue('home.policy.type')}
        />
        <DisplayValueOptional
          label="Policy term"
          value={eav?.getValue('home.policy.term')}
        />
        <DisplayValueOptional
          label="Business on premises"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasBusinessOnPremises',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Has trampoline"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasTrampoline',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Has dog on premises"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasDogOnPremises',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Dog breed"
          value={eav?.getValue('home.ratingInfo.dogBreed')}
        />
        <DisplayValueOptional
          label="Has dog bite history"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasDogBiteHistory',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Has exotic animal on premises"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasExoticAnimalOnPremises',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Exotic animal species"
          value={eav?.getValue('home.ratingInfo.exoticAnimalSpecies')}
        />
        <DisplayValueOptional
          label="Has claim history"
          value={eav?.getValue<'boolean'>('hasClaimHistory', 'boolean')}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Construction">
        <DisplayValueOptional
          label="Year built"
          value={eav?.getValue('home.ratingInfo.yearBuilt')}
        />
        <DisplayValueOptional
          label="Square feet"
          value={eav?.getValue('home.ratingInfo.squareFeet')}
        />
        <DisplayValueOptional
          label="Construction type"
          value={eav?.getValue('home.ratingInfo.constructionType')}
        />
        <DisplayValueOptional
          label="# of stories"
          value={eav?.getValue('home.ratingInfo.numberOfStories')}
        />
        <DisplayValueOptional
          label="# of bedrooms"
          value={eav?.getValue('home.ratingInfo.numberOfBedrooms')}
        />
        <DisplayValueOptional
          label="# of full bathrooms"
          value={eav?.getValue('home.ratingInfo.numberOfFullBaths')}
        />
        <DisplayValueOptional
          label="# of partial bathrooms"
          value={eav?.getValue('home.ratingInfo.numberOfPartialBaths')}
        />
        <DisplayValueOptional
          label="# of units"
          value={eav?.getValue('home.ratingInfo.numberOfUnits')}
        />
        <DisplayValueOptional
          label="Is new home"
          value={eav?.getValue<'boolean'>('residence.isNewHome', 'boolean')}
        />
        <DisplayValueOptional
          label="Roof type"
          value={eav?.getValue('home.ratingInfo.roofType')}
        />
        <DisplayValueOptional
          label="Roof shape"
          value={eav?.getValue('home.ratingInfo.roofShape')}
        />
        <DisplayValueOptional
          label="Dwelling use"
          value={eav?.getValue('home.ratingInfo.dwellingUse')}
        />
        <DisplayValueOptional
          label="# of fireplaces"
          value={eav?.getValue('home.ratingInfo.numberOfFireplaces')}
        />
        <DisplayValueOptional
          label="Property subtype"
          value={eav?.getValue('home.ratingInfo.subType')}
        />
        <DisplayValueOptional
          label="Heating type"
          value={eav?.getValue('home.ratingInfo.heatingType')}
        />
        <DisplayValueOptional
          label="Has swimming pool"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasSwimmingPool',
            'boolean'
          )}
        />
        <If
          condition={Boolean(
            eav?.getValue<'boolean'>(
              'home.ratingInfo.hasSwimmingPool',
              'boolean'
            )
          )}
        >
          <DisplayValueOptional
            label="Is pool fenced"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.isSwimmingPoolFenced',
              'boolean'
            )}
          />
          <DisplayValueOptional
            label="Pool type"
            value={eav?.getValue('home.ratingInfo.swimmingPoolType')}
          />
        </If>
        <DisplayValueOptional
          label="Solar panels"
          value={eav?.getValue<'boolean'>(
            'home.ratingInfo.hasSolarPanels',
            'boolean'
          )}
        />
        <DisplayValueOptional
          label="Electrical updates"
          value={eav?.getValue('home.ratingInfo.electricalUpdate')}
        />
        <DisplayValueOptional
          label="Plumbing updates"
          value={eav?.getValue('home.ratingInfo.plumbingUpdate')}
        />
        <DisplayValueOptional
          label="Heating updates"
          value={eav?.getValue('home.ratingInfo.heatingUpdate')}
        />
        <DisplayValueOptional
          label="Property class"
          value={eav?.getValue('home.ratingInfo.class')}
        />
      </SubheadingDivider>
      <PriorPolicy
        headingText="Existing Policy"
        eav={eav}
        attributePath="home.priorPolicy"
      />
      <SubheadingDivider headingText="Assessment Info">
        <DisplayValueOptional
          label="Assessed improvement value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.assessedImprovementValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Assessed land value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.assessedLandValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Assessed total value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.assessedTotalValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Market improvement value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.marketImprovementValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Market land value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.marketLandValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Market total value"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.marketTotalValue',
            'number'
          )}
          isCurrency
        />
        <DisplayValueOptional
          label="Purchase date"
          value={eav?.getValue('home.ratingInfo.purchaseDate')}
          isDate
        />
        <DisplayValueOptional
          label="Purchase price"
          value={eav?.getValue<'number'>(
            'home.ratingInfo.purchasePrice',
            'number'
          )}
          isCurrency
        />
      </SubheadingDivider>

      <If
        condition={Boolean(
          eav?.getChildren({ prefix: 'home.ratingInfo.protectiveDevices.' })
            ?.length
        )}
      >
        <SubheadingDivider headingText="Protective Devices">
          <DisplayValueOptional
            label="Smoke detector"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.protectiveDevices.hasSmokeDetector',
              'boolean'
            )}
          />
          <DisplayValueOptional
            label="Fire extinguisher"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.protectiveDevices.hasFireExtinguisher',
              'boolean'
            )}
          />
          <DisplayValueOptional
            label="Fire alarm"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.protectiveDevices.hasFireAlarm',
              'boolean'
            )}
          />
          <DisplayValueOptional
            label="Burglar alarm"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.protectiveDevices.hasBurglarAlarmLocal',
              'boolean'
            )}
          />
          <DisplayValueOptional
            label="Deadbolt locks"
            value={eav?.getValue<'boolean'>(
              'home.ratingInfo.protectiveDevices.hasDeadBoltLocks',
              'boolean'
            )}
          />
        </SubheadingDivider>
      </If>

      <CoverageDetailsList
        eav={eav}
        attributePath="home.coverage.coverageDetails"
      />
      {/* <ClaimList list={application?.claims ?? []} /> */}
    </Section>
  );
};

export default function WrappedHomeInfo(props: { eav?: EAV }): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in HomeInfo">
      <HomeInfo {...props} />
    </ErrorBoundary>
  );
}
