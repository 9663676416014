import { useCallback } from 'react';
import { getOpportunityList } from '@/services/document/dynamicsService';
import {
  getAccountDetails,
  getAssociatedContactList,
  getCategoryList,
  getPolicyList,
} from '@/services/document/documentService';
import type { Opportunity } from '@/models/document/opportunity';
import type { Customer } from '@/models/document/customer';
import type { Contact } from '@/models/document/contact';
import type { Policy } from '@/models/document/policy';

type LoadSupportDataProps = {
  accountId: string;
  setOpportunityList: (list: Opportunity[]) => void;
  setCategoryList: (list: string[]) => void;
  setCustomer: (customer: Customer) => void;
  setAssociatedContactList: (list: Contact[]) => void;
  setPolicyList: (list: Policy[]) => void;
};

const useLoadSupportData = (
  authedFetch: (
    input: RequestInfo | URL,
    init?: RequestInit
  ) => Promise<Response>
) => {
  const loadSupportData = useCallback(
    async ({
      accountId,
      setOpportunityList,
      setCategoryList,
      setCustomer,
      setAssociatedContactList,
      setPolicyList,
    }: LoadSupportDataProps) => {
      const [
        opportunities,
        categories,
        newCustomer,
        newAssociatedContactList,
        newPolicies,
      ] = await Promise.allSettled([
        getOpportunityList(accountId, authedFetch),
        getCategoryList(authedFetch),
        getAccountDetails(accountId, authedFetch),
        getAssociatedContactList(accountId, authedFetch),
        getPolicyList(accountId, authedFetch),
      ]);

      if (opportunities.status === 'fulfilled') {
        setOpportunityList(opportunities.value);
      }
      if (categories.status === 'fulfilled') {
        setCategoryList(categories.value);
      }
      if (newCustomer.status === 'fulfilled') {
        setCustomer(newCustomer.value);
      }
      if (newAssociatedContactList.status === 'fulfilled') {
        setAssociatedContactList(newAssociatedContactList.value);
      }
      if (newPolicies.status === 'fulfilled') {
        setPolicyList(newPolicies.value);
      }
    },
    [authedFetch]
  );

  return { loadSupportData };
};

export { useLoadSupportData };
