/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import styles from './alertToast.module.css';

/**
 * Displays a customizable toast style alert message.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {boolean} config.open - Flag indicating whether the alert should be displayed or not.
 * @param {function} config.closeAlert -The function to call to hide the alert.
 * @param {string} config.text - The text to display in the alert.
 * @param {string} config.type - Which type of alert should be displayed. Possible options are 'warning', 'info' or 'success'. 'info' is default.
 * @param {React.CSSProperties} config.additionalStyles - Optional additional styles.
 * @param {string} config.action - Optional action component.
 * @param {string} config.index - Optional index for rendering multiple toast messages.
 * @param {string} config.setToastHeights - Optional setState function to expose heights of toast messages to its parent.
 *
 * @example
 * <AlertToast open={alertToastOpen} closeAlert={() => setAlertToastOpen(false)} type={'success'} text={'Successfully saved.'} />
 */
export default function AlertToast({
  open,
  closeAlert,
  text,
  type,
  additionalStyles,
  action,
  index = 0,
  setToastHeights,
}: {
  open: boolean;
  closeAlert: any;
  text: string;
  type: string;
  additionalStyles?: React.CSSProperties;
  action?: JSX.Element;
  index?: number;
  setToastHeights?: any;
}) {
  const DEFAULT_TIMEOUT = 10000;
  let icon = 'info';

  if (type === 'error') {
    icon = 'error_outline';
  } else if (type === 'warning') {
    icon = 'error_outline';
  } else if (type === 'success') {
    icon = 'check';
  } else {
    icon = 'info';
  }

  const [height, setHeight] = useState(0);
  const ref = useRef<any>(null);

  // Measure the height of the toast once it's rendered
  useEffect(() => {
    if (ref.current && setToastHeights) {
      const height = ref.current.offsetHeight;
      setHeight(height);
    }
  }, [setToastHeights, text]);

  // Update the heights array in the parent
  useEffect(() => {
    if (height > 0 && setToastHeights) {
      setToastHeights((prevHeights: number[]) => {
        const updatedHeights = [...prevHeights];
        updatedHeights[index] = height;
        return updatedHeights;
      });
    }
  }, [height, index, setToastHeights]);

  useEffect(() => {
    if (open) {
      setTimeout(closeAlert, DEFAULT_TIMEOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open || !text) {
    return;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.container} style={additionalStyles} ref={ref}>
        <div className={[styles.toast, styles[type]].join(' ')}>
          <span
            className={'material-symbols-outlined font32'}
            onClick={closeAlert}
          >
            {icon}
          </span>
          <div className="">{text}</div>
          {action && <div className="">{action}</div>}
          <span
            className={[
              'material-symbols-outlined font24',
              styles.closeIcon,
            ].join(' ')}
            onClick={closeAlert}
          >
            close
          </span>
        </div>
      </div>
    </div>
  );
}

export interface AlertToast {
  text: string;
  type: string;
  action?: JSX.Element;
}
