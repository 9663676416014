import styles from './verticalDivider.module.css';

/**
 * Displays a vertical separator
 *
 * @example
 * <VerticalDivider />
 */
export default function VerticalDivider() {
  return <div className={styles.divider}></div>;
}
