import { Customer } from '@/models/forms/customer';
import { RequestError, request } from '@/services/common/utils';
import { EmailResponse } from '@/models/forms/emailResponse';
import { Policy } from '@/models/forms/policy';
import { EmailFormRequest } from '@/models/forms/emailRequest';
import { Contact } from '@/models/forms/contact';
import { APIForm, CompletedForm } from '@bwinsurance/shared-components';
import { Submission } from '@/models/forms/submission';

export const getAccountDetails = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Customer> => {
  const url = `/apim/customer/v1/customers/${accountId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getAssociatedContactList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Contact[]> => {
  const url = `/apim/customer/v1/customers/${accountId}/associated-contacts`;
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getPolicyList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Policy[]> => {
  const url = `/apim/policy/v1/customers/${accountId}/policies?source=dynamics`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getFormList = async (
  authedFetch: typeof fetch
): Promise<APIForm[]> => {
  const url = '/apim/forms/v1/forms/metadata?metadata={"showInFusion":true}';

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const createForm = async (
  newForm: Pick<APIForm, 'name' | 'dbAction' | 'content'>,
  authedFetch: typeof fetch
): Promise<APIForm> => {
  const url = '/apim/forms/v1/forms/';

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newForm),
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const sendEmail = async (
  name: string,
  customerId: string,
  emailAddress: string,
  emailFormRequests: EmailFormRequest[],
  dynamicsAccountId: string,
  username: string | undefined,
  personalMessage: string,
  authedFetch: typeof fetch
): Promise<EmailResponse[]> => {
  const url = '/apim/forms/v1/email/';

  const jsonBody = {
    name,
    customerId,
    emailAddresses: [emailAddress],
    forms: emailFormRequests,
    dynamicsAccountId,
    username,
    personalMessage,
  };

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonBody),
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getSubmission = async (
  submissionId: string,
  authedFetch: typeof fetch
): Promise<Submission> => {
  const url = `/apim/forms/v1/submissions/${submissionId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getForm = async (
  formKey: string,
  authedFetch: typeof fetch
): Promise<APIForm> => {
  const url = `/apim/forms/v1/forms/${formKey}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getCompleteForm = async (
  data: unknown,
  authedFetch: typeof fetch
): Promise<CompletedForm> => {
  const url = '/shared-components/getCompleteForm';

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};
