import React, { createContext, useContext, useState } from 'react';
import type { OptionsConfig } from '@/services/forms/models/formTypes';

interface FormPageContext {
  apiOptionMap: Record<string, OptionsConfig[] | undefined>;
  updateApiOptionMap: (filedName: string, optionList: OptionsConfig[]) => void;
}

const FormPageContext = createContext<FormPageContext | undefined>(undefined);

export const useFormPageContext = (): FormPageContext => {
  const context = useContext(FormPageContext);

  if (!context) {
    throw new Error(
      'usePageFormContext must be used within a Form Page Context'
    );
  }
  return context;
};

export const FormPageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [apiOptionMap, setAPIOptionMap] = useState({});

  const updateApiOptionMap = (
    fieldName: string,
    optionList: OptionsConfig[]
  ) => {
    setAPIOptionMap((prevAPIOptionMap) => ({
      ...prevAPIOptionMap,
      [fieldName]: optionList,
    }));
  };

  return (
    <FormPageContext.Provider value={{ apiOptionMap, updateApiOptionMap }}>
      {children}
    </FormPageContext.Provider>
  );
};
