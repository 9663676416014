import LeftNav, { NavItem } from '../../LeftNav';
import { IntakeNavOptions } from '@/services/common/constants';

const navItems: NavItem<IntakeNavOptions>[] = [
  {
    icon: <span className="material-symbols-outlined">person</span>,
    value: IntakeNavOptions.APPLICANT,
    tooltipText: 'Applicant',
  },
  {
    icon: <span className="material-symbols-outlined">shield</span>,
    value: IntakeNavOptions.POLICY,
    tooltipText: 'Existing Policies',
  },
  {
    icon: <span className="material-symbols-outlined">home</span>,
    value: IntakeNavOptions.HOME,
    tooltipText: 'Properties',
  },
  {
    icon: <span className="material-symbols-outlined">directions_car</span>,
    value: IntakeNavOptions.AUTO,
    tooltipText: 'Vehicles',
  },
];

const IntakeLeftNav = ({
  selected,
  setSelected,
  hiddenItems,
}: {
  selected: IntakeNavOptions;
  setSelected: React.Dispatch<React.SetStateAction<IntakeNavOptions>>;
  hiddenItems: IntakeNavOptions[];
}): JSX.Element => {
  return (
    <LeftNav
      selected={selected}
      setSelected={setSelected}
      navItems={navItems.filter((item) => !hiddenItems.includes(item.value))}
    />
  );
};

export default IntakeLeftNav;
