import logger from '@/controllers/logger';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const Logout = (): JSX.Element | null => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  instance
    .logoutRedirect()
    .catch((cause: unknown) =>
      logger.error(
        'An error occured while attempting to `logoutRedirect`.',
        cause
      )
    );
  navigate('/');
  return null;
};
