import { useState, useEffect, useCallback } from 'react';
import { IntakeNavOptions } from '@/services/common/constants';
import SideNav from '@/components/SideNav';
import Switch from '@/components/Switch';
import { NavBarItem } from '@/components/SideNavItem';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { agentIntake } from '@/services/forms/agent';
import {
  FormPage,
  ActionBanner,
  PrefillButton,
  Overlay,
} from '@/components/forms';
import { useForm, FormProvider } from 'react-hook-form';
import { defaultValuesMap } from '@/services/forms/agent/schema';
import type { DefaultValuesMap } from '@/services/forms/agent/schema';
import { useWindowConfirm } from '@/controllers/common/hooks/utility/useWindowConfirm';
import { useData } from '@/controllers/opportunity/hooks/useData';
import { flattenToEAV } from '@/services/opportunity/utils';
import { postEav } from '@/services/opportunity/network/eav';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import {
  formSearch,
  SearchResultField,
} from '@/services/opportunity/utils/formSearch';
import { SendToRaterDialog } from '@/components/forms/SendToRaterDialog';
import { useRaterIntegration } from '@/controllers/opportunity/hooks/useRaterIntegration';
import { useQuoteSubmissionForApplication } from '@/controllers/opportunity/hooks/useQuoteSubmissionForApp';
import { RaterProduct } from '@/services/forms/models/rateProductTypes';
import { useSendAndGetQuoteGroups } from '@/controllers/opportunity/hooks/useSendAndGetQuoteGroups';
import { usePopulatePrefillData } from '@/controllers/opportunity/hooks/usePopulatePrefillData';
import {
  getErrorMessage,
  getRaterButtonErrorMessage,
  ErrorType,
  checkRequiredFieldsForRaters,
  getMissingFieldsStringForRaters,
  getChangedFields,
  getCurrentFormattedDateTime,
} from '@/services/forms/helpers/utils';
import { prefillData } from '@/services/opportunity/network/prefill';
import {
  showPrefillBanner,
  checkRequiredFieldsForPrefill,
  getMissingFieldsString,
  isPrefillButtonDisabled,
  getPrefillType,
  getPrefillToast,
} from '@/services/forms/prefill/prefillHelper';
import { useAlertToast } from '@/controllers/common/hooks/useAlertToast';
import { useAgentAndUserInfo } from '@/controllers/opportunity/hooks/useAgentAndUserInfo';
import logger from '@/controllers/logger';
import FenrisLegalAcknowledgementDialog from '@/components/intake/FenrisLegalAcknowledgementDialog';

const onSubmit = (data: AgentIntakeForm) => {
  console.log(data);
};

const handleAutoSaveError = (cause: unknown) => {
  logger.error(
    'An error occurred while attempting to `autoSave` opportunity intake.',
    cause
  );
};

const agentIntakeFormSearch = (value: string) =>
  value ? formSearch(value, agentIntake) : null;

const OpportunityIntakePage = () => {
  const authedFetch = useAuthenticatedFetch();
  const { formData, loading, errorText, eavEntityId } = useData();
  const { raterProductEnabled } = useRaterIntegration({
    loading,
    crmAgentId: formData?.crm?.agentId,
  });
  const { crmData, agentName, userAgentFlags, confirmUserAgentFlag } =
    useAgentAndUserInfo({
      crmAgentId: formData?.crm?.agentId,
    });
  const { successQuoteGroups, updateSuccessQuoteGroups, successQRQuoteGroup } =
    useQuoteSubmissionForApplication({
      loading,
      applicationId: formData?.application?.id,
    });
  const { AlertToastList, addToast, removeAllToast } = useAlertToast();

  const [selectedTab, setSelectedTab] = useState<NavBarItem>({
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANTS,
    label: 'Applicant(s)',
    search_count: null,
  });
  const currentTab = selectedTab.parent || selectedTab.value;
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [raterDialogOpen, setRaterDialogOpen] = useState(false);
  const [sendingToRater, setSendingToRater] = useState(false);
  const [prefillingData, setPrefillingData] = useState(false);
  const [
    confirmingFenrisLegalAcknowledgement,
    setConfirmingFenrisLegalAcknowledgement,
  ] = useState(false);

  const [
    showFenrisLegalAcknowledgementDialog,
    setShowFenrisLegalAcknowledgementDialog,
  ] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  const [prefillSuccessful, setPrefillSuccessful] = useState(false);

  const [searchResults, setSearchResults] = useState<
    SearchResultField[] | null
  >(null);

  const formMethods = useForm<AgentIntakeForm>({
    defaultValues: agentIntake.defaults,
    mode: 'onBlur',
  });
  const { isDirty, isValid, errors } = formMethods.formState;

  useEffect(() => {
    if (formData) {
      formMethods.reset(formData);
    }
  }, [formData, formMethods]);

  const onSave = useCallback(async () => {
    let saveError = false;
    let lastSavedTimeStamp = null;

    const { dirtyFields } = formMethods.formState;

    if (!isDirty && isValid) {
      addToast([{ type: 'success', text: 'Data saved' }]);
      lastSavedTimeStamp = getCurrentFormattedDateTime();
      saveError = false;
      return { saveError, lastSavedTimeStamp };
    }

    if (!isValid || Object.keys(errors).length) {
      addToast([{ type: 'warning', text: getErrorMessage('InvalidFields') }]);
      saveError = true;
      return { saveError, lastSavedTimeStamp };
    }

    if (eavEntityId) {
      setIsSaving(true);

      try {
        const data = formMethods.getValues();
        const changedData = getChangedFields(data, dirtyFields);
        const eav = flattenToEAV(changedData, eavEntityId);

        if (!eav.length) {
          setIsSaving(false);
          addToast([{ type: 'success', text: 'Data saved' }]);
          lastSavedTimeStamp = getCurrentFormattedDateTime();
          saveError = false;
          return { saveError, lastSavedTimeStamp };
        }

        const result = await postEav({ body: eav, fetchFn: authedFetch });

        if (!result) {
          saveError = true;
          throw new Error('Failed to save EAV');
        }

        addToast([{ type: 'success', text: 'Data saved' }]);
        formMethods.reset(formMethods.getValues(), { keepValues: true });
        saveError = false;
        lastSavedTimeStamp = getCurrentFormattedDateTime();
      } catch (err) {
        console.log(err);
        addToast([
          {
            type: 'warning',
            text: 'Something went wrong while saving data. Please try again!',
          },
        ]);
        saveError = true;
      }

      setIsSaving(false);
    }

    return { saveError, lastSavedTimeStamp };
  }, [isDirty, isValid, eavEntityId, formMethods, authedFetch]);

  useWindowConfirm(isDirty, onSave);

  useEffect(() => {
    const autoSave = async () => {
      if (isDirty && !isSaving) {
        try {
          const { lastSavedTimeStamp } = await onSave();
          setLastSaved(lastSavedTimeStamp);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    };

    if (isDirty && !isSaving && prefillSuccessful) {
      autoSave().catch(handleAutoSaveError);
      setPrefillSuccessful(false);
    }

    const autoSaveInterval = setInterval(() => {
      autoSave().catch(handleAutoSaveError);
    }, 60000); // 1 minute

    return () => clearInterval(autoSaveInterval);
  }, [isDirty, isSaving, onSave, prefillSuccessful]);

  useEffect(() => {
    if (
      confirmingFenrisLegalAcknowledgement &&
      userAgentFlags?.bwFenrisLegalAcknowledgement
    ) {
      setConfirmingFenrisLegalAcknowledgement(false);

      void handlePrefill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirmingFenrisLegalAcknowledgement,
    userAgentFlags?.bwFenrisLegalAcknowledgement,
  ]);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const results = agentIntakeFormSearch(value);
    setSearchValue(value);
    setSearchResults(results);
  };

  const { sendAndGetQuoteGroups } = useSendAndGetQuoteGroups();

  const handleSendRater = async (raterProductToSend: RaterProduct[]) => {
    const missingFields = checkRequiredFieldsForRaters(
      formMethods.getValues,
      raterProductToSend
    );
    if (missingFields.length) {
      const missingFieldString = getMissingFieldsStringForRaters(missingFields);
      addToast([
        {
          type: 'warning',
          text: `To send to raters, please add the ${missingFieldString}`,
        },
      ]);
      toggleRaterDialog();
      return;
    }

    try {
      setSendingToRater(true);
      const { saveError } = await onSave();
      if (!saveError) {
        if (!eavEntityId) {
          throw new Error('Missing entity id, unable to send to rater');
        }
        const { completedQuoteGroups, toasts } = await sendAndGetQuoteGroups(
          eavEntityId,
          raterProductToSend
        );
        updateSuccessQuoteGroups([
          ...completedQuoteGroups,
          ...successQuoteGroups,
        ]);
        addToast(toasts);
      }
    } catch (error) {
      console.log(error);
      const err = error as Error;
      const errorParams = { failureMessage: err.message };
      addToast([
        {
          type: 'warning',
          text: getErrorMessage(err.name as ErrorType, errorParams),
        },
      ]);
    }
    setSendingToRater(false);
    toggleRaterDialog();
  };

  const toggleRaterDialog = () => {
    if (!raterProductEnabled.length || !formData?.insuranceProducts) {
      const toastMessage = !raterProductEnabled.length
        ? getRaterButtonErrorMessage(
            agentName,
            formData?.crm?.agentId,
            crmData?.UserId
          )
        : 'Unsupported insurance products. Unable to send data to rater(s).';

      addToast([
        {
          type: 'warning',
          text: toastMessage,
        },
      ]);
      return;
    }
    setRaterDialogOpen(!raterDialogOpen);
  };

  const { isHomeTab, prefillType, prefillAddressType } =
    getPrefillType(currentTab);

  const prefillBannerMessage = `Save time by pre-filling key ${prefillType} information. Just ensure you've entered the ${prefillAddressType} address.`;

  const { populatePrefillData } = usePopulatePrefillData({ formMethods });

  const handleFenrisLegalAcknowledgementConfirm = () => {
    setConfirmingFenrisLegalAcknowledgement(true);

    void confirmUserAgentFlag('bwFenrisLegalAcknowledgement', authedFetch);
  };

  const handlePrefill = async (event?: React.MouseEvent<HTMLElement>) => {
    event?.preventDefault();
    removeAllToast();

    if (!userAgentFlags?.bwFenrisLegalAcknowledgement) {
      setShowFenrisLegalAcknowledgementDialog(true);

      return;
    }

    const missingFields = checkRequiredFieldsForPrefill(
      isHomeTab,
      formMethods.getValues
    );
    if (missingFields.length) {
      const missingFieldString = getMissingFieldsString(
        isHomeTab,
        missingFields
      );
      addToast([
        {
          type: 'warning',
          text: `To pre-fill data, please add the ${missingFieldString}`,
        },
      ]);
      return;
    }

    const currentFocus = document.activeElement;
    try {
      setPrefillingData(true);
      const result = await prefillData({
        prefillType: currentTab,
        data: formMethods.getValues(),
        userId: crmData?.UserId,
        fetchFn: authedFetch,
      });

      const toast = getPrefillToast(result, prefillType);
      addToast([toast]);

      if (toast.type === 'success') {
        populatePrefillData(isHomeTab, result);
        setPrefillSuccessful(true);
      }

      if (currentFocus instanceof HTMLElement) {
        currentFocus.focus();
      }
    } catch (error) {
      console.log('Error prefilling data', error);
      addToast([
        {
          type: 'warning',
          text: "We're having trouble pre-filling data right now. Please contact Agent Support if this continues.",
          action: (
            <button className="underline" onClick={handlePrefill}>
              {'Try again'}
            </button>
          ),
        },
      ]);
    }
    setPrefillingData(false);
  };

  const overlayMessage =
    sendingToRater || confirmingFenrisLegalAcknowledgement
      ? 'Sending...'
      : prefillingData
      ? 'Pre-filling data...'
      : '';

  if (loading || errorText) {
    const loadingOrErrorText = loading ? 'Loading' : errorText;
    return (
      <div className="text-center text-xl mt-10">
        <span>{loadingOrErrorText}</span>
      </div>
    );
  }

  return (
    <div className="border border-[#E9E9E9] gap-5 h-full">
      <Overlay
        showOverlay={
          sendingToRater ||
          prefillingData ||
          confirmingFenrisLegalAcknowledgement
        }
        message={overlayMessage}
      />
      {AlertToastList}
      <SendToRaterDialog
        open={raterDialogOpen}
        handleSendRater={handleSendRater}
        toggleRaterDialog={toggleRaterDialog}
        raterProductEnabled={raterProductEnabled}
        successQRQuoteGroup={successQRQuoteGroup}
        agentMismatchName={agentName}
        policyType={formMethods.getValues('home.policy.type') || ''}
      />
      <FenrisLegalAcknowledgementDialog
        open={showFenrisLegalAcknowledgementDialog}
        setOpen={setShowFenrisLegalAcknowledgementDialog}
        confirm={handleFenrisLegalAcknowledgementConfirm}
      />
      <FormProvider {...formMethods}>
        <SideNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          onSearch={onSearch}
          isSaving={isSaving}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          lastSaved={lastSaved}
          toggleRaterDialog={toggleRaterDialog}
        />
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col ml-52 p-6 gap-8">
            <ActionBanner
              bannerMessage={prefillBannerMessage}
              bannerActions={
                <PrefillButton
                  handlePrefill={handlePrefill}
                  disableButton={isPrefillButtonDisabled(
                    isHomeTab,
                    formMethods.getValues()
                  )}
                  prefillType={prefillType}
                />
              }
              showBanner={showPrefillBanner(currentTab)}
            />
            <Switch selectedKey={currentTab}>
              <FormPage
                key={IntakeNavOptions.APPLICANTS}
                page={agentIntake.pages.applicants}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.APPLICANT}
                page={agentIntake.pages.applicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.COAPPLICANT}
                page={agentIntake.pages.coApplicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.AUTO}
                page={agentIntake.pages.auto}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
                formMethods={formMethods}
              />
              <FormPage
                key={IntakeNavOptions.HOME}
                page={agentIntake.pages.property}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
              <FormPage
                key={IntakeNavOptions.FLOOD}
                page={agentIntake.pages.flood}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
            </Switch>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OpportunityIntakePage;
