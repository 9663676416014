import { EAV } from '@bwinsurance/common-utils';
import { ReactNode } from 'react';
import { DisplayValueOptional } from '../../DisplayValue';
import Section from '../../Section';
import SubheadingDivider from '../../SubheadingDivider';
import { getYears } from '@/controllers/common/utils';
import ErrorBoundary from '../../ErrorBoundary';

export const PriorPolicy = ({
  eav,
  attributePath,
  headingText,
  additionalFields,
}: {
  eav?: EAV;
  attributePath?: string;
  headingText?: string;
  additionalFields?: ReactNode;
}): ReactNode => {
  if (
    !eav ||
    !attributePath ||
    !eav?.getChildren({ prefix: attributePath })?.length
  ) {
    return null;
  }

  return (
    <SubheadingDivider headingText={headingText}>
      <DisplayValueOptional
        label="Carrier"
        value={eav.getValue(`${attributePath}.carrier`)}
      />
      <DisplayValueOptional
        label="Premium"
        value={eav.getValue(`${attributePath}.premium`)}
        isCurrency
      />
      <DisplayValueOptional
        label="Years with carrier"
        value={getYears(
          eav.getValue<'number'>(`${attributePath}.monthsWithCarrier`, 'number')
        )}
      />
      <DisplayValueOptional
        label="Years with continuous coverage"
        value={getYears(
          eav.getValue<'number'>(
            `${attributePath}.monthsWithContinuousCoverage`,
            'number'
          )
        )}
      />
      <DisplayValueOptional
        label="Policy number"
        value={eav.getValue(`${attributePath}.carrierPolicyId`)}
      />
      <DisplayValueOptional
        label="Effective date"
        value={eav.getValue(`${attributePath}.effectiveDate`)}
        isDate
      />
      <DisplayValueOptional
        label="Expiration"
        value={eav.getValue(`${attributePath}.expirationDate`)}
        isDate
      />
      <DisplayValueOptional
        label="Canceled date"
        value={eav.getValue(`${attributePath}.canceledDate`)}
        isDate
      />
      <DisplayValueOptional
        label="Renewal date"
        value={eav.getValue(`${attributePath}.renewalDate`)}
        isDate
      />
      <DisplayValueOptional
        label="Commercial form of business"
        value={eav.getValue(`${attributePath}.commercialFormOfBusiness`)}
      />
      <DisplayValueOptional
        label="Reason no prior coverage"
        value={eav.getValue(`${attributePath}.reasonNoPrior`)}
      />
      <DisplayValueOptional
        label="Description"
        value={eav.getValue(`${attributePath}.description`)}
      />
      {additionalFields}
    </SubheadingDivider>
  );
};

const PriorPolicyInfo = ({ eav }: { eav?: EAV }): ReactNode => {
  if (!eav) {
    return null;
  }
  const keys = ['home.priorPolicy', 'auto.priorPolicy'];
  const getHeading = (key: string) => {
    if (key === 'home.priorPolicy') {
      return 'Home';
    }
    return 'Auto';
  };
  const withPriorPolicies = keys.filter((key) =>
    Boolean(eav.getChildren({ prefix: key })?.length)
  );
  if (!withPriorPolicies.length) {
    return null;
  }
  return (
    <Section headingText="Existing Policies" materialIcon="shield">
      {withPriorPolicies.map((prefix) => (
        <PriorPolicy
          headingText={getHeading(prefix)}
          eav={eav}
          attributePath={prefix}
          key={prefix}
        />
      ))}
    </Section>
  );
};

export default function WrappedPriorPolicyInfo(props: {
  eav?: EAV;
}): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in PriorPolicyInfo">
      <PriorPolicyInfo {...props} />
    </ErrorBoundary>
  );
}
