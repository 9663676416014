import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../../DisplayValue';
import Section from '../../Section';
import { getStringAddress, indexToString } from '@/controllers/common/utils';
import DriverInfo from '../../DriverInfo';
import CoverageDetailsList from '../../CoverageDetailsList/eav';
import SubheadingDivider from '../../SubheadingDivider';
import { PriorPolicy } from '../../PriorPolicyInfo/eav';
import ErrorBoundary from '../../ErrorBoundary';
import { EAV } from '@bwinsurance/common-utils';
import {
  EAVItem,
  Driver,
  Gender,
  DriversLicenseStatus,
  MaritalStatus,
  Education,
  Relation,
} from '@bwinsurance/meta-rater-types';
import If from '@/components/If';
import {
  eavToAddress,
  getChildObjectReduceFn,
  DriverWithFullName,
  getDriversFullNames,
} from '@/controllers/common/utils';

const buildDrivers = (eav: EAV): DriverWithFullName[] => {
  const allDriverData = eav?.getChildren({ prefix: 'drivers' });
  if (!allDriverData?.length) {
    return [];
  }
  const drivers: DriverWithFullName[] = [];
  let driver: EAVItem[] | undefined;
  let index = 0;
  do {
    const driver = eav?.getIndexed<undefined>({ prefix: 'drivers', index });
    if (driver?.length) {
      drivers.push({
        id: eav.getValue<'string'>(`drivers.${index}.id`, 'string'),
        name: {
          firstName: eav.getValue<'string'>(
            `drivers.${index}.name.firstName`,
            'string'
          ),
          middleName: eav.getValue<'string'>(
            `drivers.${index}.name.middleName`,
            'string'
          ),
          lastName: eav.getValue<'string'>(
            `drivers.${index}.name.lastName`,
            'string'
          ),
        },
        fullName: `${eav.getValue<'string'>(
          `drivers.${index}.name.firstName`,
          'string'
        )} ${
          eav.getValue<'string'>(
            `drivers.${index}.name.middleName`,
            'string'
          ) ?? ''
        } ${eav.getValue<'string'>(
          `drivers.${index}.name.lastName`,
          'string'
        )}`,
        gender: eav.getValue<'string'>(
          `drivers.${index}.gender`,
          'string'
        ) as Gender,
        dateOfBirth: eav.getValue(
          `drivers.${index}.dateOfBirth`
        ) as unknown as Date,
        education: eav.getValue<'string'>(
          `drivers.${index}.education`,
          'string'
        ) as Education,
        license: {
          licenseNumber: eav.getValue<'string'>(
            `drivers.${index}.license.licenseNumber`,
            'string'
          ),
          state: eav.getValue<'string'>(
            `drivers.${index}.license.state`,
            'string'
          ),
          licensedDate: eav.getValue(
            `drivers.${index}.license.licensedDate`
          ) as unknown as Date,
          status: eav.getValue<'string'>(
            `drivers.${index}.license.status`,
            'string'
          ) as DriversLicenseStatus,
        },
        hasLicenseRevokedSuspended: eav.getValue<'boolean'>(
          `drivers.${index}.hasLicenseRevokedSuspended`,
          'boolean'
        ),
        maritalStatus: eav.getValue<'string'>(
          `drivers.${index}.maritalStatus`,
          'string'
        ) as MaritalStatus,
        relation: eav.getValue<'string'>(
          `drivers.${index}.relation`,
          'string'
        ) as Relation,
        occupation: eav.getValue<'string'>(
          `drivers.${index}.occupation`,
          'string'
        ),
        isGoodStudent: eav.getValue<'boolean'>(
          `drivers.${index}.isGoodStudent`,
          'boolean'
        ),
        hasDriverTraining: eav.getValue<'boolean'>(
          `drivers.${index}.hasDriverTraining`,
          'boolean'
        ),
        isGoodDriver: eav.getValue<'boolean'>(
          `drivers.${index}.isGoodDriver`,
          'boolean'
        ),
        hasReceivedViolation: eav.getValue<'boolean'>(
          `drivers.${index}.hasReceivedViolation`,
          'boolean'
        ),
        hasAccidentWithinLastFiveYears: eav.getValue<'boolean'>(
          `drivers.${index}.hasAccidentWithinLastFiveYears`,
          'boolean'
        ),
        incidents: (
          eav.getChildren({ prefix: `drivers.${index}.incidents` }) ?? []
        ).reduce(getChildObjectReduceFn(3, 4), []),
      });
    }
    index++;
  } while (driver?.length);
  return drivers;
};

const buildVehicles = (eav: EAV): EAV[] => {
  const vehicles: EAV[] = [];
  let index = 0;
  let vehicleData: EAVItem[] | null = null;
  // create a list of EAV objects with keys like 'model' or 'make'
  do {
    vehicleData = eav?.getIndexed<undefined>({ prefix: 'vehicles', index });
    if (vehicleData?.length) {
      vehicles.push(
        EAV.from(
          vehicleData.map(
            // eslint-disable-next-line no-loop-func
            (item) =>
              ({
                ...item,
                attribute: item.attribute.split(`vehicles.${index}.`)[1],
              } as EAVItem)
          )
        )
      );
      index++;
    }
  } while (vehicleData?.length);
  return vehicles;
};

const AutoInfo = ({ eav }: { eav?: EAV }): ReactNode => {
  if (!eav?.getChildren({ prefix: 'auto.' })?.length) {
    return null;
  }
  const dataSource = eav?.getValue('application.metadata.canopyPullId')
    ? 'Canopy Connect'
    : 'Intake Form';

  const drivers = buildDrivers(eav);
  const vehicles = buildVehicles(eav);

  return (
    <>
      <Section headingText="Vehicles" materialIcon="directions_car_filled">
        <SubheadingDivider headingText="Policy Info">
          <DisplayValue label="Source" value={dataSource} />
          <DisplayValueOptional
            label="Effective date"
            value={eav?.getValue('auto.policy.effectiveDate')}
            isDate
          />
          <DisplayValueOptional
            label="Estimated premium"
            value={eav?.getValue('auto.estimatedPremium')}
            isCurrency
          />
          <DisplayValueOptional
            label="Multi-policy"
            value={eav?.getValue('isMultiPolicy')}
          />
          <DisplayValueOptional
            label="Has claim history"
            value={eav?.getValue('hasClaimHistory')}
          />
        </SubheadingDivider>
      </Section>
      <If condition={Boolean(vehicles.length)}>
        {vehicles?.map((vehicle, index) => (
          <Section
            headingText={`${indexToString(index)} Vehicle`}
            materialIcon="directions_car_filled"
            key={index}
          >
            <SubheadingDivider headingText="General Info">
              <DisplayValueOptional
                label="Year"
                value={vehicle.getValue('year')}
              />
              <DisplayValueOptional
                label="Make"
                value={vehicle.getValue('make')}
              />
              <DisplayValueOptional
                label="Model"
                value={vehicle.getValue('model')}
              />
              <DisplayValueOptional
                label="Trim"
                value={vehicle.getValue('subModel')}
              />
              <DisplayValueOptional
                label="VIN"
                value={vehicle.getValue('vin')}
              />
              <DisplayValueOptional
                label="Ownership"
                value={vehicle.getValue('ownership')}
              />
              <DisplayValueOptional
                label="Garaging address"
                value={getStringAddress(
                  eavToAddress(vehicle, 'garagingAddress')
                )}
              />
            </SubheadingDivider>

            <SubheadingDivider headingText="Usage">
              <DisplayValueOptional
                label="Primary driver"
                value={
                  vehicle.getValue('usage.principalDriverId')
                    ? getDriversFullNames(
                        drivers,
                        vehicle.getValue<'string'>(
                          'usage.principalDriverId',
                          'string'
                        ) ?? ''
                      )
                    : undefined
                }
              />
              <DisplayValueOptional
                label="All drivers"
                value={getDriversFullNames(
                  drivers,
                  vehicle.getValue<'string'>('driverIds', 'string') ?? ''
                )}
              />
              <DisplayValueOptional
                label="Usage"
                value={vehicle.getValue<'string'>('usage.usage', 'string')}
              />
            </SubheadingDivider>
            <PriorPolicy
              headingText="Existing Policy"
              eav={eav}
              attributePath="auto.priorPolicy"
              additionalFields={
                <>
                  <DisplayValueOptional
                    label="Full glass coverage"
                    value={vehicle.getValue<'boolean'>(
                      'coverage.isFullGlass',
                      'boolean'
                    )}
                  />
                  <DisplayValueOptional
                    label="Loan lease coverage"
                    value={vehicle.getValue<'boolean'>(
                      'coverage.hasLoanLeaseCoverage',
                      'boolean'
                    )}
                  />
                </>
              }
            />

            <CoverageDetailsList
              eav={vehicle}
              attributePath={'coverage.coverageDetails'}
            />
            {/*
            <ClaimList
              list={
                application?.claims?.filter(
                  (claim) => claim.vehicleId === vehicle.id
                ) ?? []
              }
            />
            */}
          </Section>
        ))}
      </If>
      {drivers?.map((driver, index) => (
        <Section
          headingText={`${indexToString(index)} Driver`}
          materialIcon="person"
          key={driver.id}
        >
          <DriverInfo driver={driver} />
        </Section>
      ))}
    </>
  );
};

export default function WrappedAutoInfo(props: { eav?: EAV }): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in AutoInfo">
      <AutoInfo {...props} />
    </ErrorBoundary>
  );
}
