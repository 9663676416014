import {
  booleanDropdownOptions,
  diagramOptions,
  elevationDifferenceOptions,
  floodDeductibleOptions,
  floodPolicyTypeOptions,
  floodWaitingPeriodOptions,
  floodZoneOptions,
  hasPriorPolicyOptions,
  mapPanelSuffixOptions,
} from '../helpers';
import { FormPage } from '../models/formTypes';

export const flood: FormPage = {
  id: 'flood',
  display: 'Flood',
  icon: 'water',
  actions: [
    {
      id: 'quoteRushLink',
      type: 'quoterush',
      fieldName: 'flood',
    },
  ],
  elements: [
    {
      id: 'floodElevation',
      display: 'Flood Elevation / Determination',
      type: 'section',
      elements: [
        {
          name: 'flood.floodZone',
          display: 'Flood zone',
          type: 'dropdown',
          placeholder: 'Select...',
          options: floodZoneOptions,
        },
        {
          name: 'flood.mapPanel',
          display: 'Map panel',
          type: 'text',
          placeholder: 'Enter map panel...',
        },
        {
          name: 'flood.mapPanelSuffix',
          display: 'Map panel suffix',
          type: 'dropdown',
          placeholder: 'Select...',
          options: mapPanelSuffixOptions,
        },
        {
          name: 'flood.communityNumber',
          display: 'Community number',
          type: 'text',
          placeholder: 'Enter community number...',
        },
        {
          name: 'flood.communityDescription',
          display: 'Community description',
          type: 'text',
          placeholder: 'Enter community description...',
        },
        {
          name: 'flood.hasFloodElevationCert',
          display: 'Has flood elevation certificate?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'flood.elevationCertDate',
          display: 'Elevation certificate date',
          type: 'date',
        },
        {
          name: 'flood.photographDate',
          display: 'Photograph date',
          type: 'date',
        },
        {
          name: 'flood.diagram',
          display: 'Diagram',
          type: 'dropdown',
          placeholder: 'Select...',
          options: diagramOptions,
        },
        {
          name: 'flood.elevationDifference',
          display: 'Elevation difference',
          type: 'dropdown',
          placeholder: 'Select...',
          options: elevationDifferenceOptions,
        },
        {
          name: 'flood.isGrandfathered',
          display: 'Grandfathering',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'flood.cbraZone',
          display: 'CBRA zone',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'flood.isNonParticipatingFloodCommunity',
          display: 'Non-participating flood community',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'policy',
      display: 'Policy',
      type: 'section',
      elements: [
        {
          name: 'flood.policyType',
          display: 'Policy type',
          placeholder: 'Select...',
          type: 'dropdown',
          options: floodPolicyTypeOptions,
        },
        {
          name: 'flood.waitingPeriod',
          display: 'Waiting period',
          placeholder: 'Select...',
          type: 'dropdown',
          options: floodWaitingPeriodOptions,
        },
        {
          name: 'flood.buildingCoverage',
          display: 'Building coverage',
          placeholder: 'Enter building coverage...',
          type: 'number',
        },
        {
          name: 'flood.contentsCoverage',
          display: 'Contents coverage',
          placeholder: 'Enter contents coverage...',
          type: 'number',
        },
        {
          name: 'flood.deductible',
          display: 'Flood deductible',
          placeholder: 'Select...',
          type: 'dropdown',
          options: floodDeductibleOptions,
        },
        {
          name: 'flood.hasPriorFloodLoss',
          display: 'Prior flood loss',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'priorPolicy',
      display: 'Prior policy',
      type: 'section',
      elements: [
        {
          name: 'flood.priorPolicy.hasPriorPolicy',
          display: 'Has existing coverage/policy',
          placeholder: 'Select...',
          type: 'dropdown',
          options: hasPriorPolicyOptions,
        },
        {
          name: 'flood.priorPolicy.carrier',
          display: 'Carrier',
          placeholder: 'Enter carrier...',
          type: 'text',
          visibleIf: {
            fieldName: 'flood.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
        },
        {
          name: 'flood.floodExpirationDate',
          display: 'Expiration date',
          type: 'date',
          visibleIf: {
            fieldName: 'flood.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
        },
      ],
    },
  ],
};
