import { getStringAddress } from '@/controllers/common/utils';
import { FormAction } from '@/services/forms/models/formTypes';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import { RatersEnum } from '@bwinsurance/meta-rater-types';
import Icon from '@/components/Icon';
import type { IconType } from '@/components/Icon';
import { FC } from 'react';
import { usePopulateInput } from './usePopulateInput';
import { useFormContext } from 'react-hook-form';
import { EAVAttribute } from '@bwinsurance/common-utils';

export type ActionMapperProps = {
  action: FormAction;
  quoteGroup?: QuoteGroupWithQuote;
  index?: number;
};

const ActionMapper: FC<ActionMapperProps> = ({ action, quoteGroup, index }) => {
  switch (action.type) {
    case 'zillow':
      return <ZillowAction action={action} />;
    case 'google-maps':
      return <GoogleMapsAction action={action} />;
    case 'quoterush':
      return <RaterAction action={action} quoteGroup={quoteGroup} />;
    case 'ezlynx':
      return <RaterAction action={action} quoteGroup={quoteGroup} />;
    case 'sync-fields':
      return <SyncFields action={action} index={index} />;
  }
};

export default ActionMapper;

const linkClassNames = 'text-left underline cursor-pointer text-light-text-link hover:text-light-text-primary';

const ZillowAction = ({ action }: { action: FormAction }) => {
  const { watch } = useFormContext();
  const address = watch(action.fieldName);

  if (!address || !address.lineOne || !address.city) {
    return null;
  }

  const link = `https://zillow.com/homes/${getStringAddress(address)}`;
  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={link}
        title="Open Zillow"
        target="_blank"
        rel="noreferrer"
        className={linkClassNames}
      >
        Zillow
      </a>
    </div>
  );
};

const GoogleMapsAction = ({ action }: { action: FormAction }) => {
  const { watch } = useFormContext();
  const address = watch(action.fieldName);

  if (!address || !address.lineOne || !address.city) {
    return null;
  }

  const link = `https://www.google.com/maps/place/${getStringAddress(address)}`;
  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={link}
        title="Open Google Maps"
        target="_blank"
        rel="noreferrer"
        className={linkClassNames}
      >
        Google Maps
      </a>
    </div>
  );
};

const RaterAction = ({
  action,
  quoteGroup,
}: {
  action: FormAction;
  quoteGroup?: QuoteGroupWithQuote;
}) => {
  if (!quoteGroup || !quoteGroup?.raterData?.mainLink) {
    return null;
  }

  const raterString =
    quoteGroup.rater === RatersEnum.enum.QuoteRush
      ? `QuoteRUSH (Lead ID: ${quoteGroup?.raterData?.leadId})`
      : quoteGroup.rater;
  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={quoteGroup?.raterData?.mainLink}
        title={`Open ${quoteGroup.rater}`}
        target="_blank"
        rel="noreferrer"
        className={'flex items-center gap-1 text-light-text-link'}
      >
        <span className="material-symbols-outlined text-base">north_east</span>
        <span className="text-sm">View in {raterString}</span>
      </a>
    </div>
  );
};

const SyncFields = ({
  action,
  index,
}: {
  action: FormAction;
  index?: number;
}) => {
  const { populateInputValue } = usePopulateInput();

  if (!action.syncFieldsOptions) {
    return null;
  }

  const handleOnClick = () => {
    if (!action.syncFieldsOptions?.fieldMap) {
      return;
    }
    const { fieldMap } = action.syncFieldsOptions;

    Object.entries(fieldMap).forEach(([srcField, syncFieldMapOption]) => {
      let targetField = syncFieldMapOption?.targetFieldName;

      if ((targetField as string).includes('{idx}') && index) {
        targetField = (targetField as string).replace('{idx}', String(index));
      }

      populateInputValue({
        sourceField: srcField,
        targetField: targetField as EAVAttribute,
        shouldWatchOptionList: Boolean(
          syncFieldMapOption?.shouldWatchOptionListChange
        ),
        overwriteFieldValue: syncFieldMapOption?.overwriteFieldValue,
      });
    });
  };

  return (
    <div id={action.id} data-testid={action.id} className="">
      <button
        data-testid={`${action.id}-button`}
        onClick={handleOnClick}
        className={`flex items-center gap-1 text-light-text-link ${
          action.syncFieldsOptions.underline ? 'underline' : ''
        }`}
        type={'button'}
      >
        <Icon
          type={(action.syncFieldsOptions.icon as unknown as IconType) ?? ''}
          size={8}
          color="text-secondary"
        />
        <span data-testid={`${action.id}-text`} className="text-sm">
          {action.syncFieldsOptions.actionText}
        </span>
      </button>
    </div>
  );
};
