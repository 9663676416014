import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';
import { defaultValuesMap } from '@/services/forms/agent/schema';
import { flatten } from '../helpers/utils';

const normalizeYear = (item: Record<string, any>) => {
  if (item.year) {
    item.year = String(item.year);
  }
};

const processItems = (
  items: Record<string, any> | undefined,
  defaultValues: Record<string, any>,
  appendFunction: (item: any) => void
) => {
  if (items) {
    Object.values(items).forEach((item: any) => {
      normalizeYear(item);
      appendFunction({ ...defaultValues, ...item });
    });
  }
};

export const removeEmptyVehicles = (
  formMethods: UseFormReturn<AgentIntakeForm>,
  vehicles: Record<string, any>[] | undefined,
  removeFunction: (item: any) => void
) => {
  if (vehicles?.length) {
    const allVehicles = formMethods.getValues('vehicles');
    const vehiclesToRemove: number[] = [];
    allVehicles.forEach((vehicle, index) => {
      if (!vehicle.isDeleted) {
        const flattened = flatten(vehicle);
        const hasValue = Object.values(flattened).some((val) => val);
        if (!hasValue) {
          vehiclesToRemove.push(index);
        }
      }
    });
    
    if (vehiclesToRemove.length) {
      removeFunction(vehiclesToRemove);
    }
  }
};

export const populateAuto = (
  formMethods: UseFormReturn<AgentIntakeForm>,
  data: PrefillResponse | null,
  driverFieldArray: UseFieldArrayReturn<AgentIntakeForm, 'drivers', 'id'>,
  vehicleFieldArray: UseFieldArrayReturn<AgentIntakeForm, 'vehicles', 'id'>
) => {
  const results = data?.responses;

  if (results) {
    results.forEach((dataItem) => {
      if (dataItem.target === 'auto') {
        const { drivers, vehicles } = dataItem.data;
  
        removeEmptyVehicles(formMethods, vehicles, vehicleFieldArray.remove);

        processItems(
          drivers,
          defaultValuesMap['drivers'],
          driverFieldArray.append
        );
        processItems(
          vehicles,
          defaultValuesMap['vehicles'],
          vehicleFieldArray.append
        );
      }
    });

    formMethods.setValue('drivers', formMethods.getValues('drivers'), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    formMethods.setValue('vehicles', formMethods.getValues('vehicles'), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    formMethods.setValue('prefillStatus.auto', true, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }
};
