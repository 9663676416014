import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import {
  Address,
  Application,
  AddressType,
  Driver,
  EAVItem,
} from '@bwinsurance/meta-rater-types';
import { EAV } from '@bwinsurance/common-utils';
import { dataverse, loginRequest } from '@/services/common/config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const getAccessToken = async (
  instance: IPublicClientApplication
): Promise<string> => {
  const account = instance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }
  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      const response = await instance.acquireTokenPopup(loginRequest);
      return response.accessToken;
    } else {
      throw error;
    }
  }
};

export interface WhoAmIResponse {
  '@odata.context': string;
  BusinessUnitId: string;
  UserId: string;
  OrganizationId: string;
}

export async function callCrmWhoAmI(
  accessToken: string
): Promise<WhoAmIResponse | undefined> {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Accept', 'application/json');

  const options = {
    method: 'GET',
    headers: headers,
  };

  const response = await fetch(`${dataverse.baseApi}/WhoAmI`, options);
  if (response.ok) {
    const data = (await response.json()) as WhoAmIResponse;

    return data;
  }
}

export const formatDate = (dateish?: Date | string | number | null) => {
  if (!dateish || !['string', 'object'].includes(typeof dateish)) {
    return;
  }
  return new Date(dateish).toLocaleDateString();
};

export const formatTime = (dateish?: Date | string | number | null) => {
  if (!dateish || !['string', 'object'].includes(typeof dateish)) {
    return;
  }
  return new Date(dateish).toLocaleTimeString([], {
    timeZone: 'America/New_York',
    timeZoneName: 'short',
  });
};

// deliberately returning UTC, as it seems Canopy is returning an ISO string and intending for
// the date to stay static
export const formatUTCDate = (
  dateish?: Date | string | number | null
): string | undefined => {
  if (!dateish || !['string', 'object'].includes(typeof dateish)) {
    return;
  }
  return dayjs.utc(dateish).format('MM/DD/YY');
};

export const formatUSD = (currencyish?: string | Date | number | null) => {
  if (!currencyish || !['string', 'number'].includes(typeof currencyish)) {
    return;
  }
  const dollars = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return dollars.format(Number(currencyish));
};

const prependSpace = (str?: string | null) => {
  if (str) {
    return ` ${str}`;
  }
  return '';
};

export const getStringAddress = (
  address?: Address | null
): string | undefined => {
  if (!address) {
    return;
  }
  return `${address?.lineOne ?? ''}${prependSpace(
    address.lineTwo
  )}${prependSpace(address.city)}${prependSpace(
    address.stateCode
  )}${prependSpace(address.postalCode)}`;
};

export const getDriversString = (
  app: Application,
  driverIds?: string[] | null
): string | undefined => {
  if (
    !Array.isArray(driverIds) ||
    !driverIds.length ||
    !Array.isArray(app?.drivers)
  ) {
    return;
  }
  return app?.drivers
    .filter((driver) => driverIds.includes(driver.id as string))
    .map(
      (driver) =>
        `${driver.name?.firstName} ${driver.name?.middleName ?? ''} ${
          driver.name?.lastName
        }`
    )
    .join(', ');
};

export const getYears = (months?: number | null): number | undefined => {
  if (typeof months === 'number') {
    return parseFloat((months / 12).toFixed(1));
  }
};

export const indexToString = (index: number): string => {
  const stringList = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth',
    'Tenth',
  ];
  return stringList[index] ?? (index + 1).toString();
};

export const formatPercentage = (
  value?: number | string | null | boolean
): string | undefined => {
  if (!value || !['string', 'number'].includes(typeof value)) {
    return;
  }
  try {
    let numValue: number;
    if (typeof value === 'string') {
      numValue = parseFloat(value);
      if (isNaN(numValue)) {
        console.error(`Unable to convert ${value} to number`);
        return;
      }
    } else {
      numValue = value as number;
    }
    return `${numValue}%`;
  } catch (error) {
    console.log(error);
    return value.toString();
  }
};

const timeFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
});

export const formatTerm = (
  effectiveDate: string,
  expirationDate: string
): string => {
  if (!effectiveDate || !expirationDate) {
    return '-';
  }

  return (
    timeFormat.format(new Date(effectiveDate)) +
    ' - ' +
    timeFormat.format(new Date(expirationDate))
  );
};

export const classNameSet = (...list: (string | undefined)[]): string => {
  const filteredList = list.filter((item) => !!item);
  return filteredList.join(' ');
};

export const eavToAddress = (eav: EAV, prefix: string): Address => {
  return {
    lineOne: eav.getValue<'string'>(`${prefix}.lineOne`),
    lineTwo: eav.getValue<'string'>(`${prefix}.lineTwo`),
    city: eav.getValue<'string'>(`${prefix}.city`),
    stateCode: eav.getValue<'string'>(`${prefix}.stateCode`),
    postalCode: eav.getValue<'string'>(`${prefix}.postalCode`),
    type: eav.getValue<'string'>(`${prefix}.type`) as AddressType,
  };
};

// reduce function to get an object with the correct keys and values from an array of EAV items
export const getChildObjectReduceFn =
  (indexLocation: number, keyLocation: number) =>
  (acc: any[], item: EAVItem) => {
    const split = item.attribute.split('.');
    const index = split[indexLocation] ? Number(split[indexLocation]) : null;
    const key = split[keyLocation];
    if (index === null || isNaN(index) || !key) {
      return acc;
    }
    if (!acc[index]) {
      acc[index] = {};
    }
    acc[index] = { ...acc[index], [key]: item.value };
    return acc;
  };

export interface DriverWithFullName extends Driver {
  fullName?: string | null;
}

export const getDriversFullNames = (
  drivers: DriverWithFullName[],
  driverIds?: string
): string | undefined => {
  if (!driverIds || !Array.isArray(drivers) || !drivers?.length) {
    return;
  }

  const splitIds = driverIds.split(',');
  if (!splitIds.length) {
    return;
  }
  const filteredIds = splitIds.filter(Boolean);
  return drivers
    .filter((driver) => filteredIds.includes(driver.id ?? ''))
    .map((driver) => driver.fullName)
    .join(', ');
};
