import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormPageContext } from '../FormPage/FormPageContext';

export type VehicleDetails = {
  year?: string;
  make?: string;
  model?: string;
  subModel?: string;
};

export const usePopulateAutoDetails = () => {
  const [vehicleDetails, setVehicleDetails] = useState<VehicleDetails>();
  const [modelFieldName, setModelFieldName] = useState<string>('');
  const [submodelFieldName, setSubmodelFieldName] = useState<string>('');
  const { apiOptionMap } = useFormPageContext();
  const { getValues, setValue } = useFormContext();

  const modelOptionList = useMemo(() => {
    return apiOptionMap[modelFieldName];
  }, [apiOptionMap, modelFieldName]);

  useEffect(() => {
    if (modelFieldName && modelOptionList) {
      setValue(modelFieldName, vehicleDetails?.model, {
        shouldDirty: true,
      });
      setValue(submodelFieldName, vehicleDetails?.subModel, {
        shouldDirty: true,
      });
    }
  }, [
    modelOptionList,
    modelFieldName,
    setValue,
    vehicleDetails?.model,
    vehicleDetails?.subModel,
    submodelFieldName,
  ]);

  const populateAutoDetails = (
    data: { vehicle_number: number } & VehicleDetails
  ) => {
    setVehicleDetails(data);
    const { vehicle_number, ...details } = data;
    const vehicles = getValues('vehicles');

    if (vehicles?.[vehicle_number]) {
      const specialFields: Record<string, (fieldName: string) => void> = {
        model: setModelFieldName,
        subModel: setSubmodelFieldName,
      };

      Object.entries(details).forEach(([key, value]) => {
        const fieldName = `vehicles.${vehicle_number}.${key}`;
        setValue(fieldName, value, {
          shouldDirty: true,
        });

        if (key in specialFields) {
          specialFields[key](fieldName);
        }
      });
    }
  };

  return { populateAutoDetails };
};
