import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { RaterProduct } from '@/services/forms/models/rateProductTypes';
import {
  checkQuoteGroupsStatus,
  sendQuote,
} from '@/services/opportunity/network/quote';
import { getAccessToken } from '@/controllers/common/utils';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import {
  findQuoteGroups,
  getErrorMessages,
  getSuccessToasts,
} from '@/services/forms/helpers/utils';
import { AlertToast } from '@/components/shared/alertToast';

const useSendAndGetQuoteGroups = () => {
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();

  const sendAndGetQuoteGroups = useCallback(
    async (eavEntityId: string, raterProductToSend: RaterProduct[]) => {
      let completedQuoteGroups: QuoteGroupWithQuote[] = [];
      let failedQuoteGroups: QuoteGroupWithQuote[] = [];
      const toasts: AlertToast[] = [];
      try {
        const quoteSubmission = await sendQuote({
          entityId: eavEntityId,
          raterProductToSend,
          fetchFn: authedFetch,
        });

        if (!quoteSubmission) {
          throw new Error('Failed to send to rater.');
        }
        const accessToken = await getAccessToken(instance);

        if (!accessToken) {
          throw new Error('Failed to get access token');
        }

        const quoteGroups = await checkQuoteGroupsStatus({
          applicationId: quoteSubmission.applicationId,
          quoteSubmissionId: quoteSubmission.quoteSubmissionId,
          raterProductToSend,
          accessToken,
        });

        const results = findQuoteGroups({
          raterProductToSend,
          quoteGroups: quoteGroups || [],
        });
        completedQuoteGroups = results.successQuoteGroups;
        failedQuoteGroups = results.errorQuoteGroups;

        const successToasts = getSuccessToasts(completedQuoteGroups);
        toasts.push(...successToasts);

        const messages = getErrorMessages(failedQuoteGroups);
        messages.forEach((message) => {
          toasts.push({
            type: 'warning',
            text: message,
          });
        });
      } catch (error) {
        console.log('Failed to send to rater', error);
        throw error;
      }
      return { completedQuoteGroups, toasts };
    },
    [authedFetch, instance]
  );
  return { sendAndGetQuoteGroups };
};

export { useSendAndGetQuoteGroups };
