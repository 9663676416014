import { useState, type FC, useEffect, useMemo, useCallback } from 'react';
import {
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  RaterProduct,
  RaterSelectedMap,
} from '@/services/forms/models/rateProductTypes';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';
import {
  InsuranceProduct,
  InsuranceProductEnum,
  Raters,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';
import Checkbox from '@/components/shared/checkbox';
import { consolidateRaterProducts } from '@/services/forms/helpers/utils';

export type SendToRaterDialogProps = {
  open: boolean;
  handleSendRater: (raterProductToSend: RaterProduct[]) => void;
  toggleRaterDialog: () => void;
  raterProductEnabled: RaterProduct[];
  successQRQuoteGroup?: QuoteGroupWithQuote;
  agentMismatchName: string | undefined;
  policyType: string;
};

const SendToRaterDialog: FC<SendToRaterDialogProps> = ({
  open,
  handleSendRater,
  toggleRaterDialog,
  raterProductEnabled,
  successQRQuoteGroup,
  agentMismatchName,
  policyType,
}) => {
  const [selectedRaters, setSelectRaters] = useState<RaterSelectedMap>(
    {} as RaterSelectedMap
  );

  const isMobileHomeOrDwellingFire = useMemo(() => {
    return ['DP1', 'DP3', 'MDP', 'MHO'].includes(policyType);
  }, [policyType]);

  const consolidatedRaters = useMemo(() => {
    return consolidateRaterProducts(raterProductEnabled).sort((a, b) =>
      a.rater.localeCompare(b.rater)
    );
  }, [raterProductEnabled]);

  const isEZHomeOnlyAndMobileHomeOrDwellingFire = useCallback(
    ({
      rater,
      products,
    }: {
      rater: Raters;
      products: INSURANCE_PRODUCT_TO_CRM_PRODUCT[];
    }) => {
      return (
        isMobileHomeOrDwellingFire &&
        products.length === 1 &&
        isEZHome(rater, products[0])
      );
    },
    [isMobileHomeOrDwellingFire]
  );

  useEffect(() => {
    if (consolidatedRaters.length && open) {
      const selectedRaterMap = {} as RaterSelectedMap;
      consolidatedRaters.forEach(({ rater, products }) => {
        selectedRaterMap[rater] =
          rater === RatersEnum.enum.QuoteRush
            ? !successQRQuoteGroup
            : !isEZHomeOnlyAndMobileHomeOrDwellingFire({
                rater,
                products,
              });
      });
      setSelectRaters(selectedRaterMap);
    }
  }, [
    consolidatedRaters,
    isEZHomeOnlyAndMobileHomeOrDwellingFire,
    open,
    successQRQuoteGroup,
  ]);

  const isEZHome = (
    rater: Raters,
    product: InsuranceProduct | INSURANCE_PRODUCT_TO_CRM_PRODUCT
  ) =>
    rater === RatersEnum.enum.EZLynx &&
    (product === InsuranceProductEnum.enum.Home ||
      product === INSURANCE_PRODUCT_TO_CRM_PRODUCT.Home);

  const hasEZHome = raterProductEnabled.some(({ rater, product }) =>
    isEZHome(rater, product)
  );

  if (!open || !raterProductEnabled.length) {
    return null;
  }

  const noneSelected = Object.values(selectedRaters).every(
    (checked) => !checked
  );

  const checkboxDisabled = ({
    rater,
    products,
  }: {
    rater: Raters;
    products: INSURANCE_PRODUCT_TO_CRM_PRODUCT[];
  }) => {
    const isQRAndAlreadySent =
      rater === RatersEnum.enum.QuoteRush && !!successQRQuoteGroup;
    return (
      isQRAndAlreadySent ||
      isEZHomeOnlyAndMobileHomeOrDwellingFire({ rater, products })
    );
  };

  const toggleSelectRater = ({
    rater,
    products,
  }: {
    rater: Raters;
    products: INSURANCE_PRODUCT_TO_CRM_PRODUCT[];
  }) => {
    if (checkboxDisabled({ rater, products })) {
      return;
    }
    setSelectRaters({
      ...selectedRaters,
      [rater]: !selectedRaters[rater],
    });
  };

  const onClickSend = () => {
    const filteredRaterProducts = isMobileHomeOrDwellingFire
      ? raterProductEnabled.filter(
          ({ rater, product }) => !isEZHome(rater, product)
        )
      : raterProductEnabled;

    const ratersToSend = filteredRaterProducts.filter(
      ({ rater }) => selectedRaters[rater]
    );
    if (ratersToSend.length) {
      handleSendRater(ratersToSend);
    }
  };

  const hasQuoteRush = raterProductEnabled.some(
    (raterProduct) => raterProduct.rater === RatersEnum.enum.QuoteRush
  );

  const getRaterLabel = (rater: Raters) =>
    rater === RatersEnum.enum.QuoteRush ? 'QuoteRUSH' : rater;

  return (
    <Dialog open={open}>
      <div
        className="flex justify-between items-center gap-2.5"
        data-testid={'send-to-rater-dialog-title'}
      >
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Ready to send?
        </h6>
        <Button onClick={toggleRaterDialog} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>
      <div className="mt-3 gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        <table className="w-full text-left table-auto mb-4">
          <thead>
            <tr>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Rater(s)</strong>
              </td>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Product(s)</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {consolidatedRaters.map((raterProduct) => {
              const isDisabled = checkboxDisabled(raterProduct);
              return (
                <tr key={raterProduct.rater}>
                  <td className="p-4 border-b border-[#cad0db]">
                    <Checkbox
                      label={getRaterLabel(raterProduct.rater)}
                      checked={!!selectedRaters[raterProduct.rater]}
                      disabled={isDisabled}
                      onChange={() => toggleSelectRater(raterProduct)}
                    />
                  </td>
                  <td className="p-4 border-b border-[#cad0db]">
                    {raterProduct.products.map((product, index) => {
                      const isUnsupported =
                        isMobileHomeOrDwellingFire &&
                        isEZHome(raterProduct.rater, product);
                      return (
                        <div
                          key={index}
                          className={
                            isUnsupported || isDisabled
                              ? 'text-light-text-tertiary'
                              : ''
                          }
                        >
                          {product}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {hasQuoteRush && (
          <InfoTable
            message={
              successQRQuoteGroup ? (
                <p data-testid={'already-sent-to-QR-message'}>
                  Data has been{' '}
                  <span className="font-semibold">
                    sent to QuoteRUSH already
                  </span>
                  . Please make further adjustments directly in QuoteRUSH.
                </p>
              ) : (
                <p data-testid={'send-to-QR-warning-message'}>
                  Data{' '}
                  <span className="font-semibold">
                    can only be sent to QuoteRUSH once
                  </span>
                  . You can make further adjustments directly in QuoteRUSH.
                </p>
              )
            }
          />
        )}
        {agentMismatchName && (
          <InfoTable
            message={
              <p data-testid={'agent-mismatch-warning-message'}>
                {agentMismatchName} is currently assigned to this opportunity.
                If you send now, this opportunity will show up in their Rater
                account, not yours.
              </p>
            }
          />
        )}
        {isMobileHomeOrDwellingFire && hasEZHome && (
          <InfoTable
            message={
              <p data-testid={'ez-unsupported-warning-message'}>
                Dwelling Fire and Mobile Homeowners data cannot be sent to
                EZLynx at this time. To send property data, please update the
                policy type.
              </p>
            }
          />
        )}
      </div>
      <div className="flex justify-end gap-2.5">
        <Button
          data-testid={'send-to-raters-button'}
          onClick={onClickSend}
          variant={'link'}
          className="py-1.5 px-3"
          disabled={noneSelected}
        >
          <span>Send</span>
        </Button>
        <Button onClick={toggleRaterDialog} variant={'outline'} size={'sm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

const InfoTable = ({ message }: { message: JSX.Element }) => (
  <table className="w-full text-left table-auto mb-1">
    <tbody>
      <tr>
        <td className="align-top">
          <span className="material-symbols-outlined text-sm pr-2">info</span>
        </td>
        <td>{message}</td>
      </tr>
    </tbody>
  </table>
);

export { SendToRaterDialog };
