import { Application } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../DisplayValue';
import Section from '../Section';
import {
  getStringAddress,
  getDriversString,
  indexToString,
} from '@/controllers/common/utils';
import DriverInfo from '../../components/DriverInfo';
import CoverageDetailsList from '../../components/CoverageDetailsList';
import { ClaimList } from '../ClaimList';
import SubheadingDivider from '../SubheadingDivider';
import { PriorPolicy } from '../PriorPolicyInfo';
import ErrorBoundary from '../ErrorBoundary';

const AutoInfo = ({
  application,
}: {
  application?: Application | null;
}): ReactNode => {
  if (!application) {
    return null;
  }
  const dataSource = application.metadata?.canopyPullId
    ? 'Canopy Connect'
    : 'Intake Form';

  return (
    <>
      <Section headingText="Vehicles" materialIcon="directions_car_filled">
        <SubheadingDivider headingText="Policy Info">
          <DisplayValue label="Source" value={dataSource} />
          <DisplayValueOptional
            label="Effective date"
            value={application?.effectiveDate}
            isDate
          />
          <DisplayValueOptional
            label="Estimated premium"
            value={application?.estimatedPremium}
            isCurrency
          />
          <DisplayValueOptional
            label="Multi-policy"
            value={application?.isMultiPolicy}
          />
          <DisplayValueOptional
            label="Has claim history"
            value={application?.hasClaimHistory}
          />
        </SubheadingDivider>
      </Section>
      {application.vehicles?.map((vehicle, index) => (
        <Section
          headingText={`${indexToString(index)} Vehicle`}
          materialIcon="directions_car_filled"
          key={vehicle.id}
        >
          <SubheadingDivider headingText="General Info">
            <DisplayValueOptional label="Year" value={vehicle.year} />
            <DisplayValueOptional label="Make" value={vehicle.make} />
            <DisplayValueOptional label="Model" value={vehicle.model} />
            <DisplayValueOptional label="Trim" value={vehicle.subModel} />
            <DisplayValueOptional label="VIN" value={vehicle.vin} />
            <DisplayValueOptional
              label="Ownership"
              value={vehicle.usage?.ownership}
            />
            <DisplayValueOptional
              label="Garaging address"
              value={getStringAddress(vehicle.garagingAddress)}
            />
          </SubheadingDivider>

          <SubheadingDivider headingText="Usage">
            <DisplayValueOptional
              label="Primary driver"
              value={
                vehicle.usage?.principalDriverId
                  ? getDriversString(application, [
                      vehicle.usage?.principalDriverId,
                    ])
                  : undefined
              }
            />
            <DisplayValueOptional
              label="All drivers"
              value={getDriversString(application, vehicle.driverIds)}
            />
            <DisplayValueOptional label="Usage" value={vehicle.usage?.usage} />
          </SubheadingDivider>
          <PriorPolicy
            headingText="Existing Policy"
            application={application}
            additionalFields={
              <>
                <DisplayValueOptional
                  label="Full glass coverage"
                  value={vehicle.coverage?.isFullGlass}
                />
                <DisplayValueOptional
                  label="Loan lease coverage"
                  value={vehicle.coverage?.hasLoanLeaseCoverage}
                />
              </>
            }
          />
          <CoverageDetailsList
            coverageDetailsList={vehicle.coverage?.coverageDetails}
          />
          <ClaimList
            list={
              application?.claims?.filter(
                (claim) => claim.vehicleId === vehicle.id
              ) ?? []
            }
          />
        </Section>
      ))}
      {application.drivers?.map((driver, index) => (
        <Section
          headingText={`${indexToString(index)} Driver`}
          materialIcon="person"
          key={driver.id}
        >
          <DriverInfo driver={driver} />
        </Section>
      ))}
    </>
  );
};

export default function WrappedAutoInfo(props: {
  application?: Application | null;
}): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in AutoInfo">
      <AutoInfo {...props} />
    </ErrorBoundary>
  );
}
