import {
  CUSTOMER_ID_SEARCH_PARAM,
  POLICY_ID_SEARCH_PARAM,
} from '@/pages/form/constants';
import { Policy } from '../../../../models/forms/policy';

export const getPolicyFormLink = (
  policy: Policy,
  accountId: string,
  formKey: string
): string => {
  const url = new URL(
    `/account/${accountId}/forms/${formKey}`,
    window.location.href
  );
  url.searchParams.append(CUSTOMER_ID_SEARCH_PARAM, policy.customerId);
  url.searchParams.append(POLICY_ID_SEARCH_PARAM, policy.amsPolicyId);
  return url.toString();
};

export const getAccountUpdateFormLink = (
  customerId: string,
  accountId: string,
  formKey: string
): string => {
  const url = new URL(
    `/account/${accountId}/forms/${formKey}`,
    window.location.href
  );
  url.searchParams.append(CUSTOMER_ID_SEARCH_PARAM, customerId);
  return url.toString();
};

const timeFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
});

export const formatTerm = (
  effectiveDate: string,
  expirationDate: string
): string => {
  if (!effectiveDate || !expirationDate) {
    return '-';
  }

  return (
    timeFormat.format(new Date(effectiveDate)) +
    ' - ' +
    timeFormat.format(new Date(expirationDate))
  );
};
