import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../../DisplayValue';
import Section from '../../Section';
import { getStringAddress, eavToAddress } from '@/controllers/common/utils';
import SubheadingDivider from '../../SubheadingDivider';
import ErrorBoundary from '../../ErrorBoundary';
import { EAV } from '@bwinsurance/common-utils';

export const ApplicantDisplay = ({
  eav,
  longform = false,
}: {
  eav?: EAV;
  longform?: boolean;
}): ReactNode => {
  // if no applicant data, return null
  if (!eav?.getChildren({ prefix: 'applicant.' })?.length) {
    return null;
  }

  const applicantFirstName = eav?.getValue('applicant.firstName');
  const applicantLastName = eav?.getValue('applicant.lastName');
  const headingText = `${applicantFirstName ?? ''} ${applicantLastName ?? ''}`;

  if (!longform) {
    return (
      <Section headingText={headingText} materialIcon="person">
        <SubheadingDivider headingText="General Info">
          <DisplayValue
            label="Birthdate"
            value={eav?.getValue('applicant.dateOfBirth')}
            isDate
          />
          <DisplayValueOptional
            label="Education"
            value={eav?.getValue('applicant.education')}
          />
          <DisplayValueOptional
            label="Gender"
            value={eav?.getValue('applicant.gender')}
          />
          <DisplayValueOptional
            label="Marital status"
            value={eav?.getValue('applicant.maritalStatus')}
          />
        </SubheadingDivider>
      </Section>
    );
  }
  return (
    <Section headingText="Applicant" materialIcon="person">
      <SubheadingDivider headingText="Lead Info">
        <DisplayValueOptional
          label="Business type"
          value={eav?.getValue('applicant.businessType')}
        />
        <DisplayValueOptional
          label="Applicant type"
          value={eav?.getValue('applicant.type')}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="General Info">
        <DisplayValue
          label="First name"
          value={eav?.getValue('applicant.firstName')}
        />
        <DisplayValueOptional
          label="Middle name"
          value={eav?.getValue('applicant.middleName')}
        />
        <DisplayValue
          label="Last name"
          value={eav?.getValue('applicant.lastName')}
        />
        <DisplayValueOptional
          label="Company name"
          value={eav?.getValue('applicant.companyName')}
        />
        <DisplayValueOptional
          label="Nickname"
          value={eav?.getValue('applicant.nickname')}
        />
        <DisplayValueOptional
          label="Maiden name"
          value={eav?.getValue('applicant.maidenName')}
        />
        <DisplayValueOptional
          label="SSN"
          value={eav?.getValue('applicant.socialSecurityNumber')}
        />
        <DisplayValue
          label="Birthdate"
          value={eav?.getValue('applicant.dateOfBirth')}
          isDate
        />
        <DisplayValueOptional
          label="Education"
          value={eav?.getValue('applicant.education')}
        />
        <DisplayValueOptional
          label="Gender"
          value={eav?.getValue('applicant.gender')}
        />
        <DisplayValueOptional
          label="Marital status"
          value={eav?.getValue('applicant.maritalStatus')}
        />
        <DisplayValueOptional
          label="Occupation"
          value={eav?.getValue('applicant.occupation')}
        />
        <DisplayValueOptional
          label="Years with employer"
          value={eav?.getValue<'number'>(
            'applicant.yearsWithEmployer',
            'number'
          )}
        />
        <DisplayValueOptional
          label="Years with previous employer"
          value={eav?.getValue<'number'>(
            'applicant.yearsWithPreviousEmployer',
            'number'
          )}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Contact Info">
        <DisplayValue
          label="Email address"
          value={eav?.getValue('applicant.email')}
        />
        <DisplayValueOptional
          label="Alternate email"
          value={eav?.getValue('applicant.alternateEmail')}
        />
        <DisplayValue
          label="Phone number"
          value={eav?.getValue('applicant.mainPhone')}
        />
        <DisplayValueOptional
          label="Current address"
          value={getStringAddress(
            eavToAddress(eav, 'applicant.currentAddress')
          )}
        />
        <DisplayValueOptional
          label="Previous address"
          value={getStringAddress(
            eavToAddress(eav, 'applicant.previousAddress')
          )}
        />
        <DisplayValueOptional
          label="Preferred contact method"
          value={eav?.getValue('applicant.preferredContactMethod')}
        />
        <DisplayValueOptional
          label="Preferred contact time"
          value={eav?.getValue('applicant.preferredContactTime')}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Driving Record">
        <DisplayValueOptional
          label="Driver license status"
          value={eav?.getValue('applicant.driverLicense.status')}
        />
        <DisplayValueOptional
          label="Driver license number"
          value={eav?.getValue('applicant.driverLicense.licenseNumber')}
        />
        <DisplayValueOptional
          label="Driver license state"
          value={eav?.getValue('applicant.driverLicense.state')}
        />
        <DisplayValueOptional
          label="Driver license date"
          value={eav?.getValue('applicant.driverLicense.licensedDate')}
          isDate
        />
      </SubheadingDivider>
    </Section>
  );
};

export default function WrapperApplicantDisplay(props: {
  eav?: EAV;
  longform?: boolean;
}): ReactNode {
  return (
    <ErrorBoundary>
      <ApplicantDisplay {...props} />
    </ErrorBoundary>
  );
}
