import { useEffect, useState } from 'react';
import { getRaterIntegration } from '@/services/opportunity/network/dynamics';
import {
  insuranceProductFromCrmMapping,
  mapRaterIntegrationFromRecordSet,
} from '@/services/opportunity/utils';
import {
  RaterIntegration,
  RaterIntegrationResponse,
} from '../../../models/opportunity/raterIntegration';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '@/controllers/common/utils';
import { RaterProduct } from '@/services/forms/models/rateProductTypes';
import {
  InsuranceProduct,
  InsuranceProductEnum,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';

export const useRaterIntegration = ({
  loading,
  crmAgentId,
}: {
  loading: boolean;
  crmAgentId?: string;
}) => {
  const { instance } = useMsal();

  const [raterProductEnabled, setRaterProductEnabled] = useState<
    RaterProduct[]
  >([]);

  const loadRaterIntegration = async (agentId: string) => {
    try {
      const accessToken = await getAccessToken(instance);

      if (!accessToken) {
        throw new Error('Failed to get access token');
      }
      const result: RaterIntegrationResponse[] | null =
        await getRaterIntegration(agentId, accessToken);
      const raterIntegrations: RaterIntegration[] = (result || []).map(
        mapRaterIntegrationFromRecordSet
      );

      const newRaterProductEnabled = raterIntegrations.map((item) => {
        return {
          rater: item.rater,
          product: insuranceProductFromCrmMapping[
            item.product
          ] as InsuranceProduct,
        };
      });

      const QRRaterProducts = newRaterProductEnabled.filter(
        (item) => item.rater === 'QuoteRush'
      );
      // If any QuoteRush product is configured, automatically enable all supported QuoteRush products (Home, Auto, Flood)
      if (QRRaterProducts.length) {
        const products = [
          InsuranceProductEnum.enum.Home,
          InsuranceProductEnum.enum.Auto,
          InsuranceProductEnum.enum.Flood,
        ];
        products.forEach((product) => {
          if (!QRRaterProducts.find((item) => item.product === product)) {
            newRaterProductEnabled.push({
              rater: RatersEnum.enum.QuoteRush,
              product,
            });
          }
        });
      }
      setRaterProductEnabled(newRaterProductEnabled);
    } catch (error) {
      console.log('Failed to load rater integration data', error);
      setRaterProductEnabled([]);
    }
  };

  useEffect(() => {
    if (!loading && crmAgentId) {
      loadRaterIntegration(crmAgentId).catch((cause: unknown) =>
        console.error(
          'An error occurred while attempting to `loadRaterIntegration`.',
          cause
        )
      );
    }
  }, [loading, crmAgentId]);

  return { raterProductEnabled };
};
