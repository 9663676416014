import Icon from '../Icon';
import styles from './uploadOverlay.module.css';

export default function UploadOverlay({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) {
  if (!open) {
    return;
  }
  return (
    <div className={styles.overlay} onDragLeave={onClose}>
      <Icon type={'arrow_upload'} color={'black'} size={200} />
      <h1 className={styles.label}>Drop file to upload.</h1>
    </div>
  );
}
