import { ReactNode } from 'react';
import { EAVItem } from '@bwinsurance/meta-rater-types';
import SubheadingDivider from '../../SubheadingDivider';
import Table from '../../Table';
import { formatUSD, formatPercentage } from '@/controllers/common/utils';
import { EAV } from '@bwinsurance/common-utils';

const CoverageDetailsTable = ({
  coverages,
  attributePath,
}: {
  coverages: EAVItem[];
  attributePath: string;
}): JSX.Element => {
  const headings = [
    'Type',
    'Premium',
    'Deductible',
    'Deductible percent',
    'Per person limit',
    'Per person unlimited',
    'Per incident limit',
    'Per incident limit percent',
    'Per incident unlimited',
  ];
  const coverageKeys = [
    'displayName',
    'premium',
    'deductible',
    'deductiblePercent',
    'perPersonLimit',
    'perPersonUnlimited',
    'perIncidentLimit',
    'perIncidentLimitPercent',
    'perIncidentUnlimited',
  ];
  const getFormattedValue = (key: string, item?: EAVItem) => {
    if (!item) {
      return '';
    }
    let value: string | number | boolean | null | undefined = item.value;
    const currencyKeys = [
      'premium',
      'deductible',
      'perPersonLimit',
      'perIncidentLimit',
    ];
    const percentageKeys = ['deductiblePercent', 'perIncidentLimitPercent'];
    if (currencyKeys.includes(key)) {
      value = formatUSD(Number(value));
    }
    if (percentageKeys.includes(key)) {
      value = formatPercentage(value);
    }
    if (value === false) {
      return 'No';
    }
    if (value === true) {
      return 'Yes';
    }
    return value ?? '';
  };

  // get the first index in the attribute post attributePath
  const parseIndex = (attribute: string) => {
    const index = attribute
      .split(attributePath)[1]
      .split('.')
      .find((part) => Boolean(part) && !isNaN(Number(part)));
    return Number(index ?? 0);
  };
  const lastIndex = Math.max(
    ...coverages.map((coverage) => parseIndex(coverage.attribute))
  );
  const coverageStrings: (string | number | boolean)[][] = [];
  for (let i = 0; i <= lastIndex; i++) {
    const coverage = coverages.filter(
      (coverage) => parseIndex(coverage.attribute) === i
    );

    if (coverage.length > 0) {
      coverageStrings[i] = [];
      coverageKeys.forEach((key) => {
        const coverageForKey = coverage.find((coverage) =>
          coverage.attribute.includes(key)
        );
        coverageStrings[i].push(getFormattedValue(key, coverageForKey));
      });
    }
  }

  return <Table headings={headings} rows={coverageStrings} />;
};

const CoverageDetailsList = ({
  eav,
  attributePath,
}: {
  eav?: EAV;
  attributePath: string;
}): ReactNode => {
  const coverages = eav?.getChildren({ prefix: attributePath });
  if (
    !Array.isArray(coverages) ||
    !coverages?.some((coverage) => Boolean(coverage.value))
  ) {
    return null;
  }

  const validCoverages = coverages.filter(
    (coverage) => coverage.value !== null && coverage.value !== undefined
  );

  return (
    <SubheadingDivider headingText="Coverages">
      <CoverageDetailsTable
        coverages={validCoverages}
        attributePath={attributePath}
      />
    </SubheadingDivider>
  );
};

export default CoverageDetailsList;
