import { useAuthenticatedFetch as _useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import { getAccessToken as _getAccessToken } from '@/controllers/common/utils';
import { callCrmWhoAmI as _callCrmWhoAmI } from '@/pages/profile';
import { useMsal as _useMsal } from '@azure/msal-react';
import {
  getAccountDetails as _getAccountDetails,
  getAssociatedContactList as _getAssociatedContactList,
  getFormList as _getFormList,
  getPolicyList as _getPolicyList,
  getCompleteForm,
  getSubmission,
} from '@/services/forms/formsService';
import SubmissionView from './SubmissionView';
import { useCallback, useEffect, useState } from 'react';
import { Submission } from '@/models/forms/submission';
import { CompletedForm, LoadingSpinner } from '@bwinsurance/shared-components';

interface InjectedDependencies {
  useMsal?: typeof _useMsal;
  useAuthenticatedFetch?: typeof _useAuthenticatedFetch;
  getAccessToken?: typeof _getAccessToken;
  callCrmWhoAmI?: typeof _callCrmWhoAmI;
}

interface Props {
  submissionId: string;
  injectedDependencies?: InjectedDependencies;
}

const SubmissionController: React.FC<Props> = ({
  submissionId,
  injectedDependencies,
}) => {
  const { useAuthenticatedFetch = _useAuthenticatedFetch } =
    injectedDependencies ?? {};

  const authedFetch = useAuthenticatedFetch();

  const [submissionData, setSubmissionData] = useState<Submission>();
  const [submissionError, setSubmissionError] = useState<string>();
  const [completedForm, setCompletedForm] = useState<CompletedForm>();

  const getSubmissionData = useCallback(async () => {
    try {
      setSubmissionError('');
      const submission = await getSubmission(submissionId, authedFetch);
      setSubmissionData(submission);
    } catch (error) {
      setSubmissionError(`failed to find submission: ${submissionId}`);
    }
  }, [authedFetch, submissionId]);

  const getFormData = useCallback(async () => {
    const completeFormData = await getCompleteForm(
      {
        customerId: submissionData?.content.customerId,
        policyId: submissionData?.content.policyId,
        formType: submissionData?.formKey || '',
        submitterEmail: submissionData?.content.submitterEmail,
      },
      authedFetch
    );
    setCompletedForm(completeFormData);
  }, [
    authedFetch,
    submissionData?.content.customerId,
    submissionData?.content.policyId,
    submissionData?.content.submitterEmail,
    submissionData?.formKey,
  ]);

  useEffect(() => {
    if (submissionId) {
      void getSubmissionData();
    }
  }, [getSubmissionData, submissionId]);

  useEffect(() => {
    if (submissionData) {
      void getFormData();
    }
  }, [getFormData, submissionData]);

  if (submissionError) {
    return (
      <div
        style={{
          marginTop: 100,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <p style={{ margin: 30 }}>{submissionError}</p>
      </div>
    );
  }

  if (!completedForm) {
    return (
      <div
        style={{
          marginTop: 100,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <SubmissionView
      submissionData={submissionData}
      completedForm={completedForm}
    />
  );
};

export default SubmissionController;
