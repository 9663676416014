import DisplayValue from '@/components/DisplayValue';
import { useVisible } from '@/controllers/common/hooks/utility';
import { FormField } from '@/services/forms/models/formTypes';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AddressAutoCompleteField } from '../AddressAutoComplete';
import { ComboboxDropdown } from '../ComboboxDropdown/ComboboxDropdown';
import { ApiDropdown } from '../ComboboxDropdown/ApiDropdown';
import { DatePicker } from '../DatePicker';
import { HiddenField } from '../HiddenField';
import { InputField } from '../InputField';
import { SubmodelField } from '../SubmodelField';
import { VinField } from '../VinField';
import { MaskedInput } from '../MaskedInput';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { DynamicDropdown } from '../ComboboxDropdown/DynamicDropdown';
import { SearchResultField } from '@/services/opportunity/utils/formSearch';
import ActionMapper from '../Actions';

export type FieldMapperProps = {
  field: FormField;
  searchResults?: SearchResultField[] | null;
};

const FieldMapper: FC<FieldMapperProps> = ({ field, searchResults }) => {
  const { register, setValue, getValues } = useFormContext();

  const visible = useVisible(field.visibleIf);

  useEffect(() => {
    if (!visible) {
      setValue(field.name, undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (field.syncWith) {
        setValue(field.syncWith.fieldName, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
  }, [visible, field.name]);

  if (!visible) return null;

  let inputComponent = null;

  switch (field.type) {
    case 'addressautocomplete':
      inputComponent = (
        <AddressAutoCompleteField field={field} register={register} />
      );
      break;
    case 'apidropdown':
      inputComponent = <ApiDropdown field={field} />;
      break;
    case 'bool':
      inputComponent = <RadioButtonGroup field={field} />;
      break;
    case 'date':
      inputComponent = <DatePicker field={field} />;
      break;
    case 'dropdown':
      inputComponent = <ComboboxDropdown field={field} />;
      break;
    case 'dynamicdropdown':
      inputComponent = <DynamicDropdown field={field} />;
      break;
    case 'hidden':
      inputComponent = <HiddenField field={field} register={register} />;
      break;
    case 'number':
      inputComponent = <MaskedInput field={field} />;
      break;
    case 'readonly':
      inputComponent = (
        <DisplayValue
          label={field.display}
          fieldType={field.type}
          value={getValues(field.id || field.name)}
          searchResults={searchResults}
        />
      );
      break;
    case 'vin':
      inputComponent = <VinField field={field} />;
      break;
    case 'submodel':
      inputComponent = <SubmodelField field={field} />;
      break;
    case 'telephone':
      inputComponent = <MaskedInput field={field} />;
      break;
    default:
      inputComponent = <InputField field={field} />;
  }

  return (
    <div className={field.type === 'hidden' ? 'hidden' : ''}>
      {inputComponent}
      {field.actions && (
        <div className="flex flex-row gap-2 items-center">
          {field.actions.map((action) => (
            <ActionMapper key={action.id} action={action} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FieldMapper;
