import { useCallback } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';
import { populateAuto } from '@/services/forms/prefill/populateAuto';
import { populateProperty } from '@/services/forms/prefill/populateProperty';
import { AgentIntakeForm } from '@/services/forms/agent/schema';

const usePopulatePrefillData = ({
  formMethods,
}: {
  formMethods: UseFormReturn<AgentIntakeForm>;
}) => {
  const driverFieldArray = useFieldArray({
    name: 'drivers',
    control: formMethods.control,
  });
  const vehicleFieldArray = useFieldArray({
    name: 'vehicles',
    control: formMethods.control,
  });

  const populatePrefillData = useCallback(
    (
      isHomeTab: boolean,
      data: PrefillResponse | null
    ) => {
      if (isHomeTab) {
        populateProperty(formMethods, data);
      } else {
        populateAuto(formMethods, data, driverFieldArray, vehicleFieldArray);
      }
    },
    [driverFieldArray, formMethods, vehicleFieldArray]
  );
  return { populatePrefillData };
};

export { usePopulatePrefillData };
