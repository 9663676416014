import type { FC } from 'react';
import type { DefaultValuesMap } from '@/services/forms/agent/schema';
import type { FormPage } from '@/services/forms/models/formTypes';
import type { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import Section from '../../Section';
import ActionMapper from '../Actions';
import { DynamicPanel } from '../DynamicPanel';
import { DynamicSection } from '../DynamicSection';
import { FormPanel } from '../FormPanel';
import { FormSection } from '../FormSection';
import { FormPageProvider } from './FormPageContext';
import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { SearchResultField } from '@/services/opportunity/utils/formSearch';

interface FormPageProps {
  page: FormPage;
  defaultValuesMap?: DefaultValuesMap;
  searchResults?: SearchResultField[] | null;
  quoteGroups?: QuoteGroupWithQuote[];
  formMethods?: UseFormReturn<AgentIntakeForm>;
}

const FormPage: FC<FormPageProps> = ({
  page,
  defaultValuesMap,
  searchResults,
  quoteGroups,
  formMethods,
}) => {
  const actions = page.actions ? (
    <div data-testid="rater-link-container" className="flex flex-row gap-2">
      {page.actions.map((action) => (
        <ActionMapper
          key={action.id}
          action={action}
          quoteGroup={quoteGroups?.find(
            (quoteGroup) =>
              quoteGroup.rater.toLowerCase() === action.type.toLowerCase() &&
              (quoteGroup.insuranceProduct.toLowerCase() ===
                action.fieldName.toLowerCase() ||
                (action.type === 'quoterush' &&
                  quoteGroup.insuranceProduct.toLowerCase() === 'home'))
          )}
        />
      ))}
    </div>
  ) : null;

  return (
    <FormPageProvider>
      <Section
        headingText={page.display}
        materialIcon={page.icon}
        actions={actions}
      >
        {page.elements.map((element) => {
          if (element.type === 'dynamicPanel' && defaultValuesMap) {
            const defaults =
              defaultValuesMap[element.defaults as keyof DefaultValuesMap];
            return (
              <DynamicPanel
                panel={element}
                panelDefaults={defaults}
                searchResults={searchResults}
                key={element.id}
                formMethods={formMethods}
              />
            );
          }
          if (element.type === 'dynamicSection' && defaultValuesMap) {
            const defaults =
              defaultValuesMap[element.defaults as keyof DefaultValuesMap];
            return (
              <DynamicSection
                section={element}
                sectionDefaults={defaults}
                searchResults={searchResults}
                key={element.id}
              />
            );
          }
          if (element.type === 'panel') {
            return <FormPanel panel={element} searchResults={searchResults} key={element.id}/>;
          }
          if (element.type === 'section') {
            return (
              <FormSection section={element} searchResults={searchResults} key={element.id}/>
            );
          }
          return null;
        })}
      </Section>
    </FormPageProvider>
  );
};

export { FormPage };
