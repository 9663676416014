import { EAV } from '@bwinsurance/common-utils';
import IntakeHeaderDisplay from '../../IntakeHeaderDisplay';
import { CustomerData } from '@/controllers/common/intake';

const IntakeHeader = ({
  eav,
  customerData,
}: {
  eav?: EAV;
  customerData?: CustomerData;
}): JSX.Element => {
  const applicantFirstName = eav?.getValue('applicant.firstName');
  const applicantLastName = eav?.getValue('applicant.lastName');
  const applicantName = [applicantFirstName, applicantLastName]
    .filter(Boolean)
    .join(' ');
  const businessType = eav?.getValue('applicant.businessType')
    ? `(${eav?.getValue('applicant.businessType')})`
    : '';
  const insuranceInterests = (
    eav?.getValue<'string'>('insuranceProducts') ?? ''
  )
    .split(',')
    .join(' • ');

  return (
    <header id="main-navbar" className="bg-primary text-white z-50">
      <nav className="flex items-center w-full h-20">
        <span className="pr-5 pl-6 min-w-56">
          <img
            src="/img/brightway-logo-yellow.svg"
            alt="Brightway Insurance"
            width="185"
            height="48"
            data-testid="brightway-logo"
          />
        </span>
        <IntakeHeaderDisplay
          label="Customer"
          value={applicantName}
          subValue={businessType}
        />
        <IntakeHeaderDisplay
          label="Insurance interests"
          value={insuranceInterests}
        />
        {customerData?.amsUrl && (
          <IntakeHeaderDisplay label="" value={customerData?.amsUrl} />
        )}
        {/* TODO display customerNumber after AMS sync */}
        {/* <IntakeHeaderDisplay label="AMS Customer Number" value={customerData.customerNumber} /> */}
      </nav>
    </header>
  );
};

export default IntakeHeader;
