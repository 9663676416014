import { useCallback, useEffect, useMemo, useState } from 'react';
import { Document } from '@/models/document/document';
import Checkbox from '../../shared/checkbox';
import { Column, Table, TableTextInterface } from '../../shared/table';
import Button from '../../shared/button';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import {
  addedBySortFunction,
  timeFormat,
  viewDocument,
} from '@/controllers/document/DocumentTableController';
import styles from './documentTable.module.css';
import { linkCellContent } from './documentTableCells';
import { addedByCellContent } from './documentTableCells';

interface DocumentTableInterface {
  documents: Document[] | undefined;
  setSelectedDocuments: any;
  tableText: TableTextInterface;
  showAlertToast: (type: string, text: string) => void;
  options:
    | {
        columns?: {
          link?: {
            hide?: boolean;
          };
          category?: {
            hide?: boolean;
          };
          createdAt?: {
            hide?: boolean;
          };
          createdBy?: {
            hide?: boolean;
          };
          note?: {
            hide?: boolean;
          };
        };
      }
    | undefined;
}

export default function DocumentTable({
  documents,
  setSelectedDocuments,
  tableText,
  showAlertToast,
  options,
}: DocumentTableInterface) {
  const [documentList, setDocumentList] = useState<Document[] | undefined>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  const toggleSelection = useCallback(
    (documentId: string, isChecked = false) => {
      setDocumentList((prevState: any) => {
        const documentListCopy = [...prevState];
        documentListCopy.forEach((document: Document) => {
          if (document.id === documentId) {
            document.selected = isChecked;
          }
        });
        return documentListCopy;
      });
    },
    []
  );

  const toggleSelectAll = useCallback(() => {
    const newSelectAllState = !selectAll;
    setDocumentList((prevState: any) => {
      const documentListCopy = [...(prevState ? prevState : [])];
      documentListCopy.forEach((document: Document) => {
        document.selected = newSelectAllState;
      });
      return documentListCopy;
    });

    setSelectAll(newSelectAllState);
  }, [selectAll]);

  const handleViewDocument = useCallback(
    async (document: Document) => {
      try {
        viewDocument(document, authedFetch).catch((cause: unknown) =>
          console.error(
            'An error occured while attempting to view document.',
            cause
          )
        );
      } catch (err) {
        showAlertToast('warning', 'Error viewing document.');
      }
    },
    [authedFetch, showAlertToast]
  );

  const headerContent = useCallback(
    () => (
      <Checkbox
        checked={selectAll}
        onChange={() => toggleSelectAll()}
        theme={'crm'}
      />
    ),
    [selectAll, toggleSelectAll]
  );

  const headerCellContent = useCallback(
    (row: Document, item: string) => (
      <Checkbox
        checked={!!row.selected || false}
        onChange={() => toggleSelection(row.id, !row.selected)}
        theme={'crm'}
      />
    ),
    [toggleSelection]
  );

  const nameCellContent = useCallback(
    (row: Document, item: string) => (
      <Button
        type={'link'}
        callback={() => handleViewDocument(row)}
        theme={'crm'}
      >
        {item}
      </Button>
    ),
    [handleViewDocument]
  );

  const columns = useMemo(() => {
    const newColumns: Column[] = [
      {
        label: '',
        accessor: '',
        headerClasses: `${styles.textCenter}`,
        headerContent: headerContent,
        cellClasses: `${styles.textCenter}`,
        cellContent: headerCellContent,
        sortable: false,
      },
      {
        label: 'Name',
        accessor: 'fileOriginalName',
        cellClasses: `${styles.noWrap}`,
        cellContent: nameCellContent,
        sortable: true,
      },
    ];

    if (!options?.columns?.link?.hide) {
      newColumns.push({
        label: 'Link',
        accessor: 'link',
        sortable: true,
        cellClasses: `${styles.noWrap}`,
        cellContent: linkCellContent,
      });
    }

    if (!options?.columns?.category?.hide) {
      newColumns.push({
        label: 'Category',
        accessor: 'documentType',
        cellClasses: `${styles.noWrap}`,
        sortable: true,
      });
    }

    if (!options?.columns?.createdAt?.hide) {
      newColumns.push({
        label: 'Added to Fusion',
        accessor: 'createdAt',
        cellClasses: `${styles.noWrap}`,
        cellContent: (row: Document, item: string) =>
          timeFormat.format(new Date(item)),
        sortable: true,
        sortByOrder: 'desc',
      });
    }

    if (!options?.columns?.createdBy?.hide) {
      newColumns.push({
        label: 'Added by',
        accessor: 'createdBy',
        cellClasses: `${styles.noWrap}`,
        cellContent: addedByCellContent,
        sortable: true,
        sortFunction: addedBySortFunction,
      });
    }

    if (!options?.columns?.note?.hide) {
      newColumns.push({
        label: 'Note',
        accessor: 'notes',
        cellContent: (_row: Document, item: string) => item ?? '-',
        sortable: true,
      });
    }

    return newColumns;
  }, [
    headerCellContent,
    headerContent,
    nameCellContent,
    options?.columns?.category?.hide,
    options?.columns?.createdAt?.hide,
    options?.columns?.createdBy?.hide,
    options?.columns?.link?.hide,
    options?.columns?.note?.hide,
  ]);

  useEffect(() => {
    if (documents) {
      setDocumentList(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (documentList && setSelectedDocuments) {
      setSelectedDocuments(
        documentList.filter((document: Document) => !!document.selected)
      );
    }
  }, [documentList, setSelectedDocuments]);

  useEffect(() => {
    const allSelected = documentList?.length
      ? documentList.every((doc) => doc.selected)
      : false;
    if (allSelected !== selectAll) {
      setSelectAll(allSelected);
    }
  }, [documentList]);

  return (
    <div className={styles.tableContainer}>
      <Table
        classes={styles.table}
        theme={'crm'}
        columns={columns}
        data={documentList}
        tableText={tableText}
      />
    </div>
  );
}
