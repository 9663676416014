import Icon from '../Icon';
import styles from './search.module.css';

export default function Search({
  search,
  searchValue,
}: {
  search: any;
  searchValue: string;
}) {
  return (
    <div className={styles.container}>
      <Icon type={'search'} color={'grey-300'} size={20} />
      <input
        type="text"
        placeholder="Find a file, policy, etc..."
        className={styles.input}
        onChange={search}
        value={searchValue}
      />
    </div>
  );
}
