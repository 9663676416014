import { SubmissionContent } from '@/models/forms/submission';

export const getSubmissionFormDataFromObject = (
  submissionData: SubmissionContent
): FormData => {
  const entries = Object.entries(submissionData);
  const formData = new FormData();
  for (const [key, value] of entries) {
    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      for (const valueItem of value) {
        addStringToFormData(formData, key, valueItem);
      }
    } else {
      addStringToFormData(formData, key, value);
    }
  }

  return formData;
};

const addStringToFormData = (
  mutableFormData: FormData,
  key: string,
  value: string
) => {
  mutableFormData.append(key, value);
  // Because of the initial implementation of submission data, there may be
  // multiple values for the same key separated by commas insetad of being array-separated.
  const commaSeparatedValues = value.split(',');
  for (const valueItem of commaSeparatedValues) {
    const trimmedValue = valueItem.trim();
    if (trimmedValue) {
      mutableFormData.append(key, trimmedValue);
    }
  }
  return mutableFormData;
};
