import { dset } from 'dset';
import dayjs from 'dayjs';
import type { EAVItem, EAVItemValue } from '../../../models/opportunity/eav';
import type { DynamicsOpportunity } from '../../../models/opportunity/dynamicOpportunity';
import {
  AgentIntakeForm,
  defaultValuesMap,
  FormAuto,
  FormHome,
} from '@/services/forms/agent/schema';
import { FormPriorPolicy } from '@/services/forms/agent/schema/helpers';
import { RaterIntegration } from '../../../models/opportunity/raterIntegration';
import { Raters } from '@bwinsurance/meta-rater-types';
import { INSURANCE_PRODUCT_TO_CRM_PRODUCT } from '@/services/forms/models/rateProductTypes';

export function transformToUIDateString(value: EAVItemValue) {
  if (typeof value !== 'string') {
    return value;
  }

  const yyyyMmDdRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (yyyyMmDdRegex.test(value)) {
    return dayjs(value).format('MM/DD/YYYY');
  } else {
    return value;
  }
}

export function transformToApiDateString(value: EAVItemValue) {
  if (typeof value !== 'string') {
    return value;
  }

  const mmDdYyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (mmDdYyyyRegex.test(value)) {
    return dayjs(value).format('YYYY-MM-DD');
  } else {
    return value;
  }
}

export function removeNull(value: EAVItemValue) {
  return value === null || value === 'undefined' ? undefined : value;
}

export function trimWhitespace(value: EAVItemValue) {
  return typeof value === 'string' ? value.trim() : value;
}

// will execute functions from right to left when invoked sanitizeValue(third, second, first)(value)
const sanitizeValue =
  (...transformations: ((input: EAVItemValue) => EAVItemValue)[]) =>
  (input: EAVItemValue) => {
    return transformations.reduce((result, fn) => fn(result), input);
  };

export function expandEAV(eav: EAVItem[]) {
  const formData = {};
  eav.forEach((eavItem) => {
    const sanitizedValue = sanitizeValue(
      transformToUIDateString,
      removeNull,
      trimWhitespace
    )(eavItem.value);
    dset(formData, eavItem.attribute, sanitizedValue);
  });

  return formData;
}

export function flattenToEAV(form: any, id: string) {
  const stack: { path: string; value: any }[] = [{ path: '', value: form }];
  const result: EAVItem[] = [];

  while (stack.length > 0) {
    const stackItem = stack.pop();
    if (!stackItem) continue;

    const { path, value } = stackItem;

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          stack.push({ path: `${path}[${index}]`, value: item });
        });
      } else {
        for (const key in value) {
          let newPath = key;

          if (path && path.endsWith(']')) {
            newPath = `${path}${key}`;
          } else if (path) {
            newPath = `${path}.${key}`;
          }

          stack.push({ path: newPath, value: value[key] });
        }
      }
    } else {
      // replace array brackets with dot notation & drop dot at end of path if present
      const sanitizedPath = path.replace(/\[|\]/g, '.').replace(/\.$/, '');
      const sanitizedValue = sanitizeValue(transformToApiDateString)(value);
      result.push({
        entity: id,
        attribute: sanitizedPath,
        value: sanitizedValue,
      });
    }
  }

  return result;
}

export const dynamicsOpportunitySearchFields =
  '$select=name,bw_opportunitytype,_parentaccountid_value,bw_insuranceproductspcf,bw_insuranceproductspcfjson&$expand=parentaccountid($select=bw_firstname,bw_lastname,accountid,new_customerid,telephone3,emailaddress1,businesstypecode,address1_line1,address1_line2,address1_city,address1_stateorprovince,address1_postalcode)';

export const generateEAVItem = (
  entityID: string,
  attribute: string,
  value?: string,
  source?: string
): EAVItem => {
  return {
    entity: entityID,
    attribute,
    value,
    ...(source ? { source } : {}),
  };
};

const stateNamesMap = {
  Alaska: 'AK',
  Alabama: 'AL',
  Arkansas: 'AR',
  Arizona: 'AZ',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  'District of Columbia': 'DC',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Iowa: 'IA',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Massachusetts: 'MA',
  Maryland: 'MD',
  Maine: 'ME',
  Michigan: 'MI',
  Minnesota: 'MN',
  Missouri: 'MO',
  Mississippi: 'MS',
  Montana: 'MT',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Nebraska: 'NE',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  Nevada: 'NV',
  'New York': 'NY',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Virginia: 'VA',
  Vermont: 'VT',
  Washington: 'WA',
  Wisconsin: 'WI',
  'West Virginia': 'WV',
  Wyoming: 'WY',
};
type StateNames = typeof stateNamesMap;

export const insuranceProductFromCrmMapping = {
  'Dwelling Fire': 'Home',
  Flood: 'Flood',
  'General Liability': 'Business',
  Homeowners: 'Home',
  'Home Warranty': 'Home Warranty',
  'Inland Marine (P)': 'Personal Articles',
  Life: 'Life',
  'Private Passenger Auto': 'Auto',
  'Mobile Homeowners': 'Home',
  Motorcycle: 'Motorcycle',
  'Pet Health Insurance': 'Pets',
  'Personal Travel Trailer': 'RV',
  'Umbrella (P)': 'Umbrella',
  'Watercraft (small boat)': 'Boat',
  // Condo and Renters are part of Homeowners in dynamics
  // Condo: 'Home',
  // Renters: 'Home',
};

type InsuranceProductFromCrmMapping = typeof insuranceProductFromCrmMapping;

export const getInsuranceProductsAndPolicyType = (
  prodspcjson: string
): {
  crmProductsString: string;
  convertedProductString: string;
  defaultPolicyType: string;
} => {
  const result = {
    crmProductsString: '',
    convertedProductString: '',
    defaultPolicyType: '',
  };
  if (!prodspcjson) {
    return result;
  }
  let productsArray;
  try {
    productsArray = JSON.parse(prodspcjson);
  } catch (err) {
    console.log('Dynamics product list was not JSON');
    return result;
  }

  if (!Array.isArray(productsArray)) {
    console.log('JSON products did not parse to an array');
    return result;
  }
  const crmProductNames: string[] = [];
  const productNames: string[] = [];
  const crmProducts = Object.keys(insuranceProductFromCrmMapping);
  // currently only sending home and auto to rater
  // to get through the schema validation process when send data to rater
  // crm insurance products that are not in meta-rater-types insurance product are excluded
  productsArray.forEach((item) => {
    if (item.name) {
      crmProductNames.push(item.name);
      if (crmProducts.includes(item.name)) {
        const product = item.name as keyof InsuranceProductFromCrmMapping;
        productNames.push(insuranceProductFromCrmMapping[product]);
      }
    }
  });

  let defaultType = '';
  if (crmProductNames.includes('Mobile Homeowners')) {
    defaultType = 'MHO';
  } else if (crmProductNames.includes('Dwelling Fire')) {
    defaultType = 'DP1';
  }

  return {
    crmProductsString: crmProductNames.join(','),
    convertedProductString: [...new Set(productNames)].join(','),
    defaultPolicyType: defaultType,
  };
};

export const dynamicsOpportunityToEav = (
  opportunityDetails: DynamicsOpportunity,
  entityId: string,
  opportunityId: string
): EAVItem[] => {
  const stateStr = opportunityDetails.parentaccountid.address1_stateorprovince;
  let stateCode;
  if (stateStr && typeof stateStr === 'string') {
    // check if it is already a state code
    if (stateStr.length === 2) {
      stateCode = stateStr;
    } else if (stateStr in stateNamesMap) {
      stateCode = stateNamesMap[stateStr as keyof StateNames];
    }
  }

  const { crmProductsString, convertedProductString, defaultPolicyType } =
    getInsuranceProductsAndPolicyType(
      opportunityDetails.bw_insuranceproductspcfjson
    );

  return [
    generateEAVItem(
      entityId,
      'crm.accountId',
      opportunityDetails._parentaccountid_value ?? undefined
    ),
    generateEAVItem(entityId, 'crm.opportunityId', opportunityId),
    generateEAVItem(
      entityId,
      'crm.agentId',
      opportunityDetails._bw_agent_value ?? undefined
    ),
    generateEAVItem(
      entityId,
      'crm.agencyId',
      opportunityDetails._bw_agencyid_value ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.firstName',
      opportunityDetails.parentaccountid.bw_firstname ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.lastName',
      opportunityDetails.parentaccountid.bw_lastname ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.email',
      opportunityDetails.parentaccountid.emailaddress1 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.mainPhone',
      opportunityDetails.parentaccountid.telephone3 ?? undefined
    ),
    generateEAVItem(entityId, 'insuranceProducts', convertedProductString),
    generateEAVItem(
      entityId,
      'originalCrmInsuranceProducts',
      crmProductsString
    ),
    generateEAVItem(entityId, 'home.policy.type', defaultPolicyType),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.lineOne',
      opportunityDetails.parentaccountid.address1_line1 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.lineTwo',
      opportunityDetails.parentaccountid.address1_line2 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.city',
      opportunityDetails.parentaccountid.address1_city ?? undefined
    ),
    generateEAVItem(entityId, 'applicant.currentAddress.stateCode', stateCode),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.postalCode',
      opportunityDetails.parentaccountid.address1_postalcode ?? undefined
    ),
  ];
};

export const setDynamicDefaults = (
  expandedEav: Partial<AgentIntakeForm>
): AgentIntakeForm => {
  const vehicleDefaults = defaultValuesMap.vehicles;
  const driverDefaults = defaultValuesMap.drivers;

  const updatedFormData = structuredClone(expandedEav);

  // fix data condition when auto and/or home has prior policy data
  // but hasPriorPolicy is not set
  checkAndSetHasPriorPolicyField(updatedFormData);

  convertInsuranceProductsFromCrm(updatedFormData);

  if (
    updatedFormData.drivers &&
    updatedFormData.drivers.length > 0 &&
    updatedFormData.vehicles &&
    updatedFormData.vehicles.length > 0
  ) {
    return updatedFormData as AgentIntakeForm;
  }

  const { drivers, vehicles, applicant } = updatedFormData;

  if (!drivers || drivers.length === 0) {
    updatedFormData.drivers = [
      {
        ...driverDefaults,
        ...(applicant
          ? {
              name: {
                firstName: applicant.firstName,
                lastName: applicant.lastName,
              },
              gender: applicant.gender,
              dateOfBirth: applicant.dateOfBirth,
              education: applicant.education,
              maritalStatus: applicant.maritalStatus,
              relation: applicant.relation,
              employmentStatus: applicant.employmentStatus,
              industry: applicant.industry,
              occupation: applicant.occupation,
            }
          : {}),
      },
    ];
  }

  if (!vehicles || vehicles.length === 0) {
    updatedFormData.vehicles = [vehicleDefaults];
  }

  return updatedFormData as AgentIntakeForm;
};

export const checkAndSetHasPriorPolicyField = (
  updatedFormData: Partial<AgentIntakeForm>
) => {
  const setHasPriorPolicy = (priorPolicy: FormPriorPolicy) => {
    if (priorPolicy.carrier && !priorPolicy.hasPriorPolicy) {
      priorPolicy.hasPriorPolicy = 'hasCurrentPolicy';
    }
  };

  const extractPriorPolicy = (product?: FormAuto | FormHome) => {
    if (product && product.priorPolicy) {
      setHasPriorPolicy(product.priorPolicy);
    }
  };

  const { home, auto } = updatedFormData;
  extractPriorPolicy(home);
  extractPriorPolicy(auto);
};

export const convertInsuranceProductsFromCrm = (
  updatedFormData: Partial<AgentIntakeForm>
) => {
  if (updatedFormData.insuranceProducts) {
    const crmProducts = Object.keys(insuranceProductFromCrmMapping);
    const updatedInsuranceProducts = updatedFormData.insuranceProducts
      .split(',')
      .filter(Boolean)
      .map((insuranceProduct) => {
        const trimmedProduct = insuranceProduct.trim();
        if (crmProducts.includes(trimmedProduct)) {
          return insuranceProductFromCrmMapping[
            trimmedProduct as keyof InsuranceProductFromCrmMapping
          ];
        }
        return trimmedProduct;
      });
    updatedFormData.insuranceProducts = updatedInsuranceProducts.join(',');
  }
};

export const mapRaterIntegrationFromRecordSet = (
  data: Record<string, unknown>
): RaterIntegration => {
  return {
    agentId: data._bw_agent_value as string,
    status: data.statuscode as number,
    rater: data['bw_rater@OData.Community.Display.V1.FormattedValue'] as Raters,
    tenantId: data.bw_tenantid as string,
    userId: data.bw_userid as string,
    product: data[
      '_bw_product_value@OData.Community.Display.V1.FormattedValue'
    ] as INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  };
};

export const delay = (timeInMs: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeInMs));
};

export const migrateExistingEav = (eav: EAVItem[]) => {
  const needMigrateData = eav.find((item) =>
    item.attribute.includes('usage.vin')
  );
  if (!needMigrateData) {
    return eav;
  }
  return eav.map((item) => ({
    ...item,
    attribute: item.attribute.includes('usage.vin')
      ? item.attribute.replace('usage.vin', 'vin')
      : item.attribute,
  }));
};

export const clearDependentVehicleFields = (
  fieldName: string,
  setValue: (
    name: string,
    value: any,
    options: {
      shouldValidate: boolean;
      shouldDirty: boolean;
      shouldTouch: boolean;
    }
  ) => void
) => {
  const parts = fieldName.split('.');
  if (parts.length < 3 || parts[0] !== 'vehicles') return;

  const vehicleIndex = parts[1];
  const fieldType = parts[2];

  const fieldDependencies: Record<string, string[]> = {
    year: ['make', 'model', 'subModel'],
    make: ['model', 'subModel'],
    model: ['subModel'],
  };

  const dependencies = fieldDependencies[fieldType];
  if (!dependencies) return;

  dependencies.forEach((dependentField) => {
    setValue(`vehicles.${vehicleIndex}.${dependentField}`, '', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  });
};
