import { useState, useEffect } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { Select } from '@/components/shared/select';
import Icon from '@/components/Icon';
import { Document, DocumentFieldType } from '@/models/document/document';
import { saveCategory } from '@/services/document/documentService';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import styles from './recategorize.module.css';
import { ListOption } from '@/models/common/options';
import {
  buildCategorySelectHandler,
  getMappedCategories,
  resetOptionListState,
} from '@/controllers/document/DocumentDialogController';

export default function Recategorize({
  selectedDocumentList,
  open,
  closeDialog,
  category,
  categoryList,
  showAlertToast,
  deselectAll,
  refreshData,
  updateField,
}: {
  selectedDocumentList: Document[];
  open: boolean;
  closeDialog: any;
  category: string;
  categoryList: string[];
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: DocumentFieldType,
    value: any
  ) => void;
}) {
  const [newCategory, setNewCategory] = useState<string>(category || '');
  const [categoryOptionList, setCategoryOptionList] = useState<ListOption[]>(
    []
  );
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      const mappedValues = getMappedCategories(categoryList);
      setCategoryOptionList(mappedValues);
    }
  }, [open]);

  const handleCategorySelect = buildCategorySelectHandler(
    categoryOptionList,
    ({
      category,
      categoryOptionList: categoryOptionListCopy,
    }: {
      category: string;
      categoryOptionList: ListOption[];
    }) => {
      setNewCategory(category);
      setCategoryOptionList(categoryOptionListCopy);
    }
  );

  const save = () => {
    setLoading(true);
    saveCategory(selectedDocumentList, newCategory, authedFetch)
      .then((result: Document[]) => {
        showAlertToast('success', `Category changed to "${newCategory}"`);
        resetThenClose();
        deselectAll();
        selectedDocumentList.forEach((document: Document) => {
          updateField(document.id, 'category', newCategory);
        });
        refreshData();
      })
      .catch(() => {
        showAlertToast('warning', 'Error changing category');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setNewCategory(category || '');
    setCategoryOptionList(resetOptionListState);

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Change category</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <Select
          label={'Category'}
          theme={'crm'}
          options={categoryOptionList}
          placeholder={'Select a category...'}
          updateValue={handleCategorySelect}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={save}
          type={'primary'}
          theme={'crm'}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
