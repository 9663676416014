import React, { useEffect, useState, useCallback, type FC } from 'react';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';
import { useAuthenticatedFetch } from '../../../controllers/common/hooks/useAuthenticatedFetch';

type SubModelVehicle = {
  vin: string | undefined;
  vehicle_number: number | undefined;
};

export type VinDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subModelVehicle: SubModelVehicle | undefined;
  setSubModelVehicle: React.Dispatch<
    React.SetStateAction<SubModelVehicle | undefined>
  >;
  assignVehicleData: (
    vehicle_number: number,
    year: string,
    make: string,
    model: string,
    submodel: string
  ) => void;
};

type SubModelItem = {
  year: string;
  make: string;
  model: string;
  bodyStyle: string;
};

const VinDialog: FC<VinDialogProps> = ({
  open,
  setOpen,
  subModelVehicle,
  setSubModelVehicle,
  assignVehicleData,
}) => {
  const [subModelData, setSubModelData] = useState<SubModelItem | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const authedFetch = useAuthenticatedFetch();

  const fetchSubModelData = useCallback(async () => {
    if (subModelVehicle?.vin) {
      const { vin } = subModelVehicle;
      const url = `apim/metarater/v1/lookups/auto/vin?vin=${encodeURIComponent(
        vin
      )}`;

      try {
        setLoading(true);

        const response = await authedFetch(url);

        if (response.status === 404) {
          setError(
            "We couldn't find that vehicle. Please manually enter the details instead."
          );
          setSubModelData(null);
          return;
        }

        const data = await response.json();

        setSubModelData(data);
        setError(null);
      } catch (error) {
        setError(
          "We're having trouble looking up that vehicle. Please try again, or manually enter the details instead."
        );
      } finally {
        setLoading(false);
      }
    }
  }, [authedFetch, subModelVehicle]);

  useEffect(() => {
    if (open) {
      fetchSubModelData().catch((cause: unknown) =>
        console.error(
          'An error occured while attempting to `fetchSubModelData`.',
          cause
        )
      );
    }
  }, [open, fetchSubModelData]);

  const handleClose = () => {
    setOpen(false);
    setSubModelVehicle(undefined);
    setSubModelData(null);
  };

  const handlePopulate = (submodelVehicleData: SubModelItem) => {
    if (subModelVehicle) {
      assignVehicleData(
        subModelVehicle.vehicle_number ?? 0,
        submodelVehicleData.year,
        submodelVehicleData.make,
        submodelVehicleData.model,
        submodelVehicleData.bodyStyle
      );
      handleClose();
    }
  };

  return (
    <Dialog open={open} width={800}>
      <div
        className="flex justify-between items-center gap-2.5"
        data-testid={'vin-dialog-title'}
      >
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Populate vehicle info?
        </h6>
        <Button onClick={handleClose} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>

      <div className="mt-3 gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        {loading ? (
          <p>Loading vehicle data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : subModelData ? (
          <>
            <table className="w-full table-auto border-collapse">
              <thead>
                <tr className="border-b">
                  <th className="text-left p-2">Year</th>
                  <th className="text-left p-2">Make</th>
                  <th className="text-left p-2">Model</th>
                  <th className="text-left p-2">Submodel</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b group">
                  <td className="p-2">{subModelData.year}</td>
                  <td className="p-2">{subModelData.make}</td>
                  <td className="p-2">{subModelData.model}</td>
                  <td className="p-2">{subModelData.bodyStyle}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-end mt-4 space-x-2">
              <Button
                onClick={() => handlePopulate(subModelData)}
                className="bg-[#2C6DB7] text-white py-2 px-4"
              >
                Populate
              </Button>
              <Button
                onClick={() => handleClose()}
                className="bg-white text-black py-2 px-4 border border-gray-300"
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <p>No vehicle data available</p>
        )}
      </div>
    </Dialog>
  );
};

export { VinDialog };
